import axios, { AxiosError, AxiosResponse } from 'axios';
import { Commit, Dispatch } from 'vuex';

export const CONNECTION_ABORTED = 'ECONNABORTED';
export const URL_VPN_CHECK = 'https://www.vanderwesten.info/';

export default {
  state: {
    hasVPN: false,
    loadingVPN: false,
    vpnIp: null,
  },
  mutations: {
    SET_HAS_VPN(state: any, hasVPN: boolean) {
      state.hasVPN = hasVPN;
    },
    SET_LOADING_VPN(state: any, loadingVPN: boolean) {
      state.loadingVPN = loadingVPN;
    },
    SET_VPN_IP(state: any, ip: boolean) {
      state.vpnIp = ip;
    },
  },
  actions: {
    CHECK_SITE_AVAILABLE({ commit }: { commit: Commit }, imageUrl: string) {
      return new Promise((resolve) => {
        const img = document.createElement('img');

        const timeout = setTimeout(() => {
          img.src = '';
          resolve(false);
        }, 2000);

        img.onload = () => {
          clearTimeout(timeout);
          resolve(true);
        };

        img.onerror = () => {
          clearTimeout(timeout);
          resolve(false);
        };

        img.src = `${imageUrl}?rand=${Math.round(Math.random() * 1000000)}`;
      });
    },
    CHECK_MULTIPLE_SITE_AVAILABLE({ dispatch }: { dispatch: Dispatch }, imageUrls: Array<string>) {
      return Promise.all(imageUrls.map((url) => dispatch('CHECK_SITE_AVAILABLE', url)))
        .then((responses) => responses.some((s) => s));
    },
    CHECK_HAS_VPN({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }) {
      commit('SET_LOADING_VPN', true);
      return axios
        .get(`${URL_VPN_CHECK}?rand=${Math.round(Math.random() * 1000000)}`)
        .then((response: AxiosResponse) => {
          commit('SET_HAS_VPN', response.data.vpn_connected);
          commit('SET_VPN_IP', response.data.source_ip);
        })
        .catch(() => commit('SET_HAS_VPN', false))
        .finally(() => commit('SET_LOADING_VPN', false));
    },
  },
  getters: {
    hasVPN: (state: any) => state.hasVPN,
    getVPNIp: (state: any) => state.vpnIp,
    isLoadingVPN: (state: any) => state.loadingVPN,
  },
};

<template lang="pug">
  v-img(width="100%" :height="height")
    l-map(
      ref="map"
      :options="mergedMapOptions"
    )
      l-tile-layer(
        :url="tileUrl"
      )
      slot(name="default")
</template>

<script>
import Vue from 'vue';
import {
  LMap,
  LTileLayer,
} from 'vue2-leaflet';

import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

// eslint-disable-next-line
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // eslint-disable-next-line
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default Vue.extend({
  name: 'LeafletMap',
  props: {
    mapOptions: Object,
    height: {
      type: [String, Number, null],
      default: 500,
    },
  },

  data() {
    return {
      tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    };
  },
  components: {
    LMap,
    LTileLayer,
  },
  mounted() {
    const map = this.$refs.map.mapObject;
    setTimeout(map.invalidateSize.bind(map), 1000);
  },
  computed: {
    mergedMapOptions() {
      return {
        zoom: 7,
        center: [52, 5],
        ...this.mapOptions,
      };
    },
  },
});
</script>

<style lang="scss">
.leaflet-popup-content {
  margin: 0;
}
.leaflet-popup-content-wrapper {
  background: transparent;
  box-shadow: none;
}
.leaflet-popup-tip-container {
  margin-top: -1px;
}
</style>

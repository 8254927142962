import { Map } from '../models';

export default {
  FUEL_LOW: 25,
  FUEL_MED: 50,
};

export const ASSET_TYPE_GENERATOR = '720ec9e0-b7c1-46ae-9840-cd35bca0d86d';
export const ASSET_TYPE_INVERTER = '29ac65cb-d8c2-49b5-9dd0-fc8eaf9ac683';
export const ASSET_TYPE_COOLING_CELL = '237168db-03a4-4bfd-bf4f-6a72ddfa0a24';

export const SENSOR_TYPE_AGGREGATE = 'aggregate';
export const SENSOR_TYPE_CONTINUOUS = 'continuous';
export const SENSOR_TYPE_ALARM = 'alarm';
export const SENSOR_TYPE_BOOLEAN = 'boolean';

export function base64Src(src: string): string {
  if (src.startsWith('http')) {
    return src;
  }
  const signatures = {
    R0lGOD: 'image/gif',
    iVBORw0KGgo: 'image/png',
    '/9j/': 'image/jpg',
    PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0i: 'image/svg+xml',
  };
  let data = 'image/jpeg';
  Object.entries(signatures).forEach(([s, mime]) => {
    if (src.indexOf(s) === 0) {
      data = mime;
    }
  });
  return `data:${data};base64, ${src}`;
}

export const SENSOR_TYPES: Map = {
  FUEL: {
    UUID: [
      'f5e8466b-f8f3-5482-95ee-c86ad0893bbe', // 101 synced rut
    ],
  },
  UL1L2: {
    UUID: [
      'c39dbca5-e3e8-5fd3-a7fa-04be4c210a49',
      '03ad1b45-52f3-582a-9b68-3f24dfd0ff6f', // 101
    ],
  },
  FGEN: {
    UUID: [
      '42654e11-42d9-5bb0-a50e-319b92a214cf',
      'dffbbc94-34b6-50c6-854a-56d9a1f54760', // 101
    ],
  },
  PGEN: {
    UUID: [
      '4e1e98f0-d119-5e7e-9d47-b3ad2b461e12',
      '24f220f3-9345-594a-8b4f-e1cdb9dc0e0b', // 101
    ],
  },
  IL1: {
    UUID: [
      '2a987c14-2c2e-5e74-b89f-2211850a51db',
      '6026a343-7a81-504f-a9a0-00c53dec1133', // 101
    ],
  },
  LAT: {
    UUID: [
      'ef52af80-bfb7-59d3-8e6d-cfe8e7d1521a',
    ],
  },
  LON: {
    UUID: [
      '32bae053-9073-59d2-ab5b-fe3ddf47ec93',
    ],
  },
  PS_ASS_ENABLED: {
    UUID: [
      '0083c321-5bf2-58a9-b72d-abc53d9d324c',
    ],
  },
  PS_ASS_START: {
    UUID: [
      'ee58f65c-3240-510d-9b98-77b6b84d1928',
    ],
  },
  PS_ASS_STOP: {
    UUID: [
      'ea517586-f433-5d50-801d-0dd909e02f3e',
    ],
  },
  PS_ENABLED: {
    UUID: [
      '7a913d93-d2f9-5811-b409-ed9eb1c3fffb',
    ],
  },
  PS_INSTALLED: {
    UUID: [
      'e602c4c6-63e5-5f2f-9fd8-abac2941abce',
    ],
  },
  PS_NET_MAX: {
    UUID: [
      'fdabd7ae-aa5e-5c33-96da-86aa54b62eb6',
    ],
  },
  PS_NET: {
    UUID: [
      '836d90fd-ac32-5fd0-bf89-71c6cbc58915',
    ],
  },
  VTRON_SOC: {
    UUID: [
      '964bfbb4-e7cc-556b-ae85-4c8dfce9cca1',
    ],
  },
  VTRON_BATTERY_VOLTAGE: {
    UUID: [
      '5f805a99-e240-54fd-99ab-58fbd3a4e29b',
    ],
  },
  VTRON_OUT_FREQ: {
    UUID: [
      '7c656648-e86e-5a08-8197-26df3e864edf',
    ],
  },
  VTRON_OUT_VOLT_1: {
    UUID: [
      '56d4180f-186c-598c-a747-5b8dfe60c7a7',
    ],
  },
  VTRON_OUT_VOLT_2: {
    UUID: [
      '9236ad2b-d339-5f00-93fc-d4b930bfd51c',
    ],
  },
  VTRON_OUT_VOLT_3: {
    UUID: [
      '90e8ee3b-6f75-5cfb-8d65-fd4a4e0a6ed9',
    ],
  },
  VTRON_OUT_CURRENT_1: {
    UUID: [
      '6a111b11-0d3f-55f9-949e-31ee21fa0f0f',
    ],
  },
  VTRON_OUT_CURRENT_2: {
    UUID: [
      'b368684e-400d-52b6-a745-d120cb06fb50',
    ],
  },
  VTRON_OUT_CURRENT_3: {
    UUID: [
      '9bc35c32-c6fd-5b4a-8c1d-e43b34ab441d',
    ],
  },
  ROOM_PB1: {
    UUID: [
      '0a000f60-cfe3-578d-8b42-d16ef65a68ad',
    ],
  },
};

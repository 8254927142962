import Asset from '@/models/asset';
import { Sensor } from './sensor';
import SensorTypeCalculation, { Aggregation } from './sensor-type-calculation';

export default class AssetProject {
  id: string;

  name: string;

  sensorTypeCalculations: Array<SensorTypeCalculation>;

  assetIds: Array<string>;

  groups: Array<string> = [];

  constructor(
    id: string,
    name: string,
    sensorTypeCalculations: Array<SensorTypeCalculation>,
    assetIds: Array<string>,
    groups: Array<string>,
  ) {
    this.id = id;
    this.name = name;
    this.sensorTypeCalculations = sensorTypeCalculations;
    this.assetIds = assetIds;
    this.groups = groups;
  }

  public calculateAggregatedValues(allAssets: Array<Asset>) {
    const assets = allAssets.filter((asset: Asset) => this.assetIds.includes(asset.id));

    const values = this.sensorTypeCalculations
      .map((calculation) => {
        const sensors = assets
          .map((asset: Asset) => asset.sensors
            .find((sensor: Sensor) => sensor.sensorType.id === calculation.sensorTypeId))
          .filter((sensor: any) => !!sensor);

        const sensorValues = sensors.map((sensor: Sensor | any) => sensor?.lastMeasurement?.value);

        let calculated: any = sensorValues;
        const sum = sensorValues.reduce((a, b) => a + b, 0);
        if (calculation.aggregation === Aggregation.Average) {
          calculated = (sum / sensorValues.length) || 0;
        } else if (calculation.aggregation === Aggregation.Sum) {
          calculated = sum;
        } else if (calculation.aggregation === Aggregation.Minimum) {
          calculated = Math.min.apply(null, sensorValues);
        } else if (calculation.aggregation === Aggregation.Maximum) {
          calculated = Math.max.apply(null, sensorValues);
        }

        return {
          sensorType: sensors?.[0]?.sensorType,
          calculation,
          calculated,
        };
      });
    return values;
  }
}

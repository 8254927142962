var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background })},[(_vm.showBar && (_vm.hasVPN || _vm.isDevelopment || _vm.$auth.isAuthenticated))?_c('v-system-bar',{attrs:{"app":"","dark":"","color":_vm.determineVPNColor}},[_c('v-icon',[_vm._v(_vm._s(_vm.determineVPNIcon))]),_c('span',{staticClass:"mr-3"},[_vm._v("VPN "+_vm._s(_vm.hasVPN ? _vm.$t('vpn.connected') : _vm.$t('vpn.disconnected'))+" "+_vm._s(_vm.getVPNIp))]),(_vm.isLoadingVPN)?_c('v-icon',[_vm._v("mdi-refresh mdi-spin")]):_vm._e(),(_vm.isDevelopment)?_c('v-btn',{attrs:{"x-small":""},on:{"click":_vm.testNotification}},[_c('v-icon',[_vm._v("mdi-bell")]),_vm._v("Test notificatie")],1):_vm._e(),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.showBar = false}}},[_vm._v("mdi-close")])],1):_vm._e(),(_vm.currentNotification)?_c('v-snackbar',{attrs:{"top":"","right":"","color":_vm.currentNotification.type,"transition":"fade-transition","multi-line":"","vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [(_vm.currentNotification.btnText)?_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.$router.push(_vm.currentNotification.btnTo), _vm.notification = false}}},'v-btn',attrs,false),[(_vm.currentNotification.btnIcon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.currentNotification.btnIcon))]):_vm._e(),_vm._v(_vm._s(_vm.currentNotification.btnText))],1):_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.notification = false}}},'v-btn',attrs,false),[_vm._v(_vm._s(_vm.$t('close')))])]}}],null,false,543772514),model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}},[_vm._v(_vm._s(_vm.currentNotification.message))]):_vm._e(),(!_vm.isFullscreenPage && _vm.$auth.isAuthenticated)?_c('v-navigation-drawer',{attrs:{"app":"","permanent":_vm.$vuetify.breakpoint.lgAndUp,"dark":"","color":"primary","width":"300"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',{attrs:{"dense":""}},[(_vm.currentOrganization)?_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('v-img',{attrs:{"src":_vm.currentOrganization.logoSrc,"contain":""}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Asset Monitoring & Control")]),_c('v-list-item-subtitle',[(_vm.currentOrganization)?_c('span',[_vm._v(_vm._s(_vm.currentOrganization.name))]):_c('span',[_vm._v("HOB IT Services B.V.")])])],1)],1),_c('v-divider')]},proxy:true},{key:"append",fn:function(){return [_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-menu',{attrs:{"offset-x":"","transition":"scale-transition","origin":"bottom left","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-monitor-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('pages.status-overview')))])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1)]}}],null,false,2303578395),model:{value:(_vm.statusBoardMenu),callback:function ($$v) {_vm.statusBoardMenu=$$v},expression:"statusBoardMenu"}},[_c('v-card',{attrs:{"width":"400"}},[_c('v-list-item',{on:{"click":function($event){return _vm.statusBoardOpen()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('assets.all')))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.getOrganizations,"multiple":"","hint":_vm.$t('assets.show-from-companies'),"persistent-hint":"","item-text":"name","item-value":"id","small-chips":"","dense":"","solo-inverted":"","append-outer-icon":"mdi-open-in-new","app":""},on:{"click:append-outer":function($event){return _vm.statusBoardOpenFiltered()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.statusBoardOrgIds.length - 1)+" "+_vm._s(_vm.$t('others'))+")")]):_vm._e()]}}],null,false,3220949750),model:{value:(_vm.statusBoardOrgIds),callback:function ($$v) {_vm.statusBoardOrgIds=$$v},expression:"statusBoardOrgIds"}})],1)],1)],1)],1),_c('v-divider'),_c('ProductByHOB',{attrs:{"dense":""}})]},proxy:true}],null,false,3488803040),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('DesktopNotifications'),_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',_vm._l((_vm.routes),function(r,i){return _c('v-list-item',{key:i,attrs:{"to":r,"exact":""}},[_c('v-list-item-icon',[_c('v-badge',{attrs:{"value":r.name === 'assets' && (_vm.assetInAlarm || _vm.assetInMalfunction),"color":"red","dot":""}},[_c('v-icon',[_vm._v(_vm._s(r.meta.icon))])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("ucFirst")(r.meta.title)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("ucFirst")(r.meta.subtitle)))])],1)],1)}),1),(_vm.myExternalResources.length)?[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('third-party.external')))]),_vm._l((_vm.myExternalResources),function(resource,i){return _c('v-list-item',{key:i,attrs:{"link":"","target":"_blank"},on:{"click":function($event){return _vm.$store.dispatch('DOWNLOAD_RDP', {             hostname: resource.host,             name: resource.name,             domain: resource.domain,           })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(resource.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(resource.name))]),(resource.type === 'rdp')?_c('v-list-item-subtitle',[_vm._v("RDS Server")]):_vm._e()],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(!_vm.hasVPN)?_c('v-list-item-action',[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-shield-off")])],1):_vm._e()]}}],null,true)},[_vm._v(_vm._s(_vm.$t('vpn.connection'))+": "+_vm._s(_vm.$t('vpn.disconnected')))])],1)})]:_vm._e()],2)],1):_vm._e(),(!_vm.isFullscreenPage && _vm.$auth.isAuthenticated)?_c('v-app-bar',{attrs:{"color":_vm.appBarColor,"app":"","elevate-on-scroll":"","dark":_vm.$vuetify.breakpoint.mdAndDown}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),(_vm.$route && _vm.$route.meta && _vm.$route.meta.title)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("ucFirst")(_vm.$route.meta.title)))]):_vm._e(),_c('v-spacer'),_c('v-autocomplete',{staticClass:"hidden-sm-and-down",attrs:{"loading":_vm.isLoadingAssets,"hide-details":"","prepend-inner-icon":"mdi-magnify","single-line":"","label":_vm.$t('assets.search'),"filled":"","dense":"","clearable":"","items":_vm.getAssets,"item-text":"name","item-value":"id","return-object":""},model:{value:(_vm.searchedAsset),callback:function ($$v) {_vm.searchedAsset=$$v},expression:"searchedAsset"}}),_c('v-spacer'),_c('v-menu',{attrs:{"fixed":"","top":"","transition":"slide-y-transition","origin":"top","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"width":"300","flat":"","color":"transparent"}},'v-card',attrs,false),on),[_c('UserAccountListItem',{attrs:{"actions":true}})],1):_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,2105875174)},[_c('v-card',{attrs:{"width":"300"}},[_c('UserAccountListItem',{attrs:{"actions":false}}),_c('v-divider'),_c('v-card-text',[_c('v-switch',{attrs:{"label":_vm.$t('dark-mode')},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}}),_c('v-select',{attrs:{"items":_vm.langs,"label":"Language"},on:{"input":_vm.updateLocale},model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-logout")]),_vm._v(_vm._s(_vm.$t('auth.logout')))],1)],1)],1)],1)],1):_vm._e(),_c('v-main',[_c('router-view')],1),(_vm.isLoadingApp)?_c('v-overlay',{attrs:{"color":"primary","z-index":"10000"}},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.loadingAppMessage))]),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"white"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { ActionContext, Commit, Dispatch } from 'vuex';
import Vue from 'vue';
import { AxiosResponse } from 'axios';
import { Sensor } from '@/models/sensor';
import { SensorType } from '@/models/sensor-type';
import { SensorMeasurement } from '@/models/sensor-measurement';

export default {
  state: {
    realtimeMeasurments: {},
    sensorTypes: [],
    loadingSensorTypes: false,
    loadedSensorTypes: false,
    sensorMeasurements: {},
    loadingSensorMeasurements: {},
  },
  mutations: {
    ADD_REALTIME_MEASUREMENT(state: any, sensor: Sensor) {
      const currentMeasurements = state.realtimeMeasurments?.[sensor.id] || [];
      currentMeasurements.push(sensor.lastMeasurement);
      Vue.prototype.$set(state.realtimeMeasurments, sensor.id, currentMeasurements);
    },
    SET_SENSOR_TYPES(state: any, sensorTypes: any): void {
      state.sensorTypes = sensorTypes;
    },
    SET_LOADING_SENSOR_TYPES(state: any, loading: boolean): void {
      state.loadingSensorTypes = loading;
    },
    SET_LOADED_SENSOR_TYPES(state: any, loaded: boolean): void {
      state.loadedSensorTypes = loaded;
    },
    SET_SENSOR_MEASUREMENTS(state: any, options: {
      last: string,
      assetId: string,
      sensorId: string,
      measurements: any,
    }) {
      Vue.prototype.$set(
        state.sensorMeasurements,
        `${options.assetId}-${options.sensorId}-${options.last}`,
        options.measurements,
      );
    },
    SET_LOADING_SENSOR_MEASUREMENTS(state: any, options: {
      last: string,
      assetId: string,
      sensorId: string,
      loading: boolean,
    }) {
      Vue.prototype.$set(
        state.loadingSensorMeasurements,
        `${options.assetId}-${options.sensorId}-${options.last}`,
        options.loading,
      );
    },
  },
  actions: {
    ADD_REALTIME_MEASUREMENT({ commit }: { commit: Commit }, sensor: Sensor) {
      commit('ADD_REALTIME_MEASUREMENT', sensor);
    },
    GET_SENSOR_MEASUREMENTS({ commit, dispatch, getters }: {
      commit: Commit,
      dispatch: Dispatch,
      getters: any,
    }, options: {
      assetId: string,
      sensorId: string,
      last: string,
    }) {
      if (getters.isLoadingSensorMeasurements(options)) return false;
      let measurements = getters.getSensorMeasurements(options);
      if (measurements) {
        return measurements;
      }
      commit('SET_LOADING_SENSOR_MEASUREMENTS', {
        ...options,
        loading: true,
      });
      const { assetId, sensorId, last } = {
        ...options,
      };
      return Vue.prototype.$hobApi.get(
        `/assets/${assetId}/sensors/${sensorId}/measurements/${last}`,
      )
        .then((response: AxiosResponse) => {
          measurements = response.data.data;
          commit('SET_SENSOR_MEASUREMENTS', {
            ...options,
            measurements,
          });
          return measurements;
        })
        .finally(() => {
          commit('SET_LOADING_SENSOR_MEASUREMENTS', {
            ...options,
            loading: false,
          });
        });
    },
    GET_SENSOR_TYPES({ commit, state, getters }: ActionContext<any, any>) {
      if (state.loadedSensorTypes) {
        return getters.getSensorTypes;
      }
      commit('SET_LOADING_SENSOR_TYPES', true);

      return Vue.prototype.$hobApi.get('/sensor-types')
        .then((response: AxiosResponse) => {
          commit('SET_SENSOR_TYPES', response.data.data);
          commit('SET_LOADED_SENSOR_TYPES', true);
          return response.data;
        })
        .finally(() => {
          commit('SET_LOADING_SENSOR_TYPES', false);
        });
    },
  },
  getters: {
    getRealtimeMeasurements: (state: any) => (sensorId: string) => state
      .realtimeMeasurments?.[sensorId],
    getSensorTypes: (state: any) => state.sensorTypes,
    hasSensorTypes: (state: any) => state.sensorTypes.length > 0,
    loadedSensorTypes: (state: any) => state.loadedSensorTypes,
    getSensorType: (state: any) => (id: string) => state.sensorTypes
      .find((s: SensorType) => s.id === id),
    getSensorMeasurements: (state: any) => (options: {
      last: string,
      assetId: string,
      sensorId: string,
    }) => state.sensorMeasurements[`${options.assetId}-${options.sensorId}-${options.last}`],
    isLoadingSensorMeasurements: (state: any) => (options: {
      last: string,
      assetId: string,
      sensorId: string,
    }) => !!state.loadingSensorMeasurements[`${options.assetId}-${options.sensorId}-${options.last}`],
  },
};

import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { ActionContext } from 'vuex';
import { AlarmType } from '@/models/alarm-type';
import { Sensor } from '@/models/sensor';

export default {
  state: {
    alarms: [],
    loadingAlarms: false,
    loadedAlarms: false,
  },
  mutations: {
    SET_ALARMS(state: any, alarms: Array<AlarmType>) {
      state.alarms = alarms;
    },
    SET_LOADING_ALARMS(state: any, loading: boolean) {
      state.loadingAlarms = loading;
    },
    SET_LOADED_ALARMS(state: any, loaded: boolean) {
      state.loadedAlarms = loaded;
    },
  },
  actions: {
    GET_ALARMS(context: ActionContext<any, any>, { force = false }: { force?: boolean } = {}) {
      if (context.getters.hasAlarms && !force) {
        return context.getters.getAlarms;
      }
      context.commit('SET_LOADING_ALARMS', true);
      return Vue.prototype.$hobApi
        .get('/alarms')
        .then((response: AxiosResponse) => {
          const alarms = response.data.data.map((a: AlarmType) => ({
            ...a,
          }));
          context.commit('SET_ALARMS', alarms);
          context.commit('SET_LOADED_ALARMS', true);
        })
        .finally(() => {
          context.commit('SET_LOADING_ALARMS', false);
        });
    },
    ADD_ALARM(context: ActionContext<any, any>, alarm: AlarmType) {
      return Vue.prototype.$hobApi
        .post('/alarms', alarm)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ALARMS', { force: true });
          return response.data.data;
        });
    },
    UPDATE_ALARM(context: ActionContext<any, any>, alarm: AlarmType) {
      return Vue.prototype.$hobApi
        .put(`/alarms/${alarm.id}`, alarm)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ALARMS', { force: true });
          return response.data.data;
        });
    },
    DELETE_ALARM(context: ActionContext<any, any>, alarm: AlarmType) {
      return Vue.prototype.$hobApi
        .delete(`/alarms/${alarm.id}`)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ALARMS', { force: true });
          return response.data;
        });
    },
  },
  getters: {
    getAlarms: (state: any) => state.alarms,
    hasAlarms: (state: any) => state.alarms.length > 0,
    loadedAlarms: (state: any) => state.loadedAlarms,
    getAlarm: (state: any) => (id: string) => state.alarms
      .find((a: AlarmType) => a.id === id),
    getAlarmsForAssetId: (state: any) => (id: string) => state.alarms
      .filter((a: AlarmType) => a.assetIds.includes(id)),
    getAlarmsForSensor: (state: any, getters: any) => (sensor: Sensor) => getters
      .getAlarmsForAssetId(sensor.assetId)
      .filter((a: AlarmType) => a.sensorTypeIds.includes(sensor.sensorType.id)),
    isLoadingAlarms: (state: any) => state.loadingAlarms,
  },
};

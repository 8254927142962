import { base64Src } from '@/helpers/consts';

export default class Organization {
  id: string;

  name: string;

  hostnames: Array<string>;

  logo: string;

  color: string;

  groups: Array<string> = [];

  constructor(
    id: string,
    name: string,
    hostnames: Array<string>,
    logo: string,
    color: string,
    groups: Array<string>,
  ) {
    this.id = id;
    this.name = name;
    this.hostnames = hostnames;
    this.logo = logo;
    this.color = color;
    this.groups = groups;
  }

  public isCurrent(): boolean {
    return this.hostnames.includes(window.location.hostname);
  }

  public get logoSrc(): string {
    return base64Src(this.logo);
  }
}

import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import Home from '@/views/Home.vue';
import store from '@/store';
import i18n from '@/i18n';
import { ROLE_EDITOR } from '@/plugins/msal';
import helpers from '@/helpers';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: i18n.t('pages.dashboard'),
      subtitle: i18n.t('pages.my-overview'),
      icon: 'mdi-home-outline',
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: i18n.t('auth.login'),
      icon: 'mdi-account',
      hiddenMenu: true,
      authRequired: false,
    },
  },
  {
    path: '/status-overview',
    name: 'status-overview',
    component: () => import('@/views/StatusOverview.vue'),
    props: (route: Route) => ({ organizationIds: route.query.organizationIds }),
    meta: {
      title: i18n.t('pages.status-overview'),
      hiddenMenu: true,
      fullscreen: true,
      authRequired: true,
    },
  },
  {
    path: '/assets/:view?',
    name: 'assets',
    component: () => import('@/views/Assets.vue'),
    props: (route: Route) => ({ view: route?.params?.view ?? '' }),
    meta: {
      title: i18n.tc('assets.asset', 2),
      subtitle: i18n.t('assets.view-manage'),
      icon: 'mdi-router-wireless',
      authRequired: true,
    },
  },
  {
    path: '/asset/:id',
    name: 'asset',
    component: () => import('@/views/Asset.vue'),
    props: (route: Route) => ({ assetId: route.params.id }),
    meta: {
      title: i18n.tc('assets.asset', 1),
      icon: 'mdi-router-wireless',
      hiddenMenu: true,
      authRequired: true,
    },
  },
  {
    path: '/management/:type?',
    name: 'management',
    component: () => import('@/views/management/Management.vue'),
    props: (route: Route) => ({ selectedSettingsType: route.params.type }),
    meta: {
      title: i18n.t('pages.manage'),
      subtitle: i18n.t('pages.manage-sub'),
      icon: 'mdi-cogs',
      role: ROLE_EDITOR,
      authRequired: true,
    },
  },
  {
    path: '/alarms',
    name: 'alarms',
    component: () => import('@/views/Alarms.vue'),
    meta: {
      title: i18n.tc('alarms.alarm', 2),
      subtitle: i18n.t('alarms.manage'),
      icon: 'mdi-bell-alert',
      role: ROLE_EDITOR,
      authRequired: true,
    },
  },
  {
    path: '/*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: i18n.t('404.title'),
      hiddenMenu: true,
      authRequired: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const current = store.getters.currentOrganization;
  const titleSuffix = current ? `${current.name} - ` : '';
  document.title = `${helpers.ucFirst(to?.meta?.title)} - ${titleSuffix} Asset Monitoring & Control`;

  window.scrollTo(0, 0);

  if (to.meta?.authRequired && !Vue.prototype.$auth.isAuthenticated) {
    next({ name: 'login' });
  }

  // From login or to login page AND is authenticated, redirect to home.
  if (to.name === 'login' && Vue.prototype.$auth.isAuthenticated) {
    next({ name: 'home' });
  }

  store.dispatch('STOP_LOADING_APP');
  next();
});

export default router;

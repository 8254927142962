export enum Aggregation {
  Maximum = 'max',
  Minimum = 'min',
  Sum = 'sum',
  Average = 'avg',
  No = 'no',
}

export const aggregationIcon = {
  [Aggregation.Maximum]: 'mdi-arrow-collapse-up',
  [Aggregation.Minimum]: 'mdi-arrow-collapse-down',
  [Aggregation.Sum]: 'mdi-plus',
  [Aggregation.Average]: 'mdi-distribute-horizontal-center',
  [Aggregation.No]: 'mdi-format-list-bulleted',
};

export const aggregations: Array<Aggregation> = Object.values(Aggregation);

export default class SensorTypeCalculation {
  sensorTypeId: string;

  aggregation: Aggregation;

  constructor(
    sensorTypeId: string,
    aggregation: Aggregation,
  ) {
    this.sensorTypeId = sensorTypeId;
    this.aggregation = aggregation;
  }
}

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import helpers from '@/helpers';
import nl from './locales/nl.json';
import en from './locales/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: helpers.locale,
  fallbackLocale: 'en',
  messages: {
    en, nl,
  },
});

i18n.locale = helpers.locale;

export default i18n;

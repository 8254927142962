import { ActionContext, Commit } from 'vuex';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import User from '@/models/auth/user';

function responseToUser(responseData: any): User {
  return new User(
    responseData.id,
    responseData.mail,
    responseData.mobilePhone,
    responseData.displayName,
    responseData.givenName,
    responseData.surname,
    responseData.companyName,
    responseData.groups?.map((v: any) => ({
      value: v.id,
      text: v.displayName || v.id,
    })) || [],
    responseData.roles || [],
  );
}

export default {
  state: {
    users: [],
    loadingUsers: false,
    loadingGroupMember: false,
  },
  mutations: {
    SET_USERS(state: any, users: Array<User>) {
      state.users = users;
    },
    ADD_USER(state: any, user: User) {
      state.users.push(user);
    },
    SET_LOADING_USERS(state: any, loading: boolean) {
      state.loadingUsers = loading;
    },
    SET_LOADING_GROUP_MEMBER(state: any, loading: string) {
      state.loadingGroupMember = loading;
    },
    ADD_GROUP_TO_USER(state: any, user: User) {
      state.users.push(user);
    },
    ADD_GROUP_MEMBER(state: any, {
      group,
      userId,
    }: { group: any, userId: string}) {
      state.users.find((u: any) => u.id === userId).addGroup(group);
    },
    REMOVE_GROUP_MEMBER(state: any, {
      groupId,
      userId,
    }: { groupId: string, userId: string}) {
      state.users.find((u: any) => u.id === userId).removeGroup(groupId);
    },
  },
  actions: {
    GET_USERS(context: ActionContext<any, any>, { force = false }: { force?: boolean } = {}) {
      if (context.getters.hasUsers && !force) {
        return context.getters.getUsers;
      }
      context.commit('SET_LOADING_USERS', true);

      return Vue.prototype.$hobApi
        .get('/users')
        .then((response: AxiosResponse) => {
          const users = response.data.data.map((u: any) => responseToUser(u));

          context.commit('SET_USERS', users);
          return users;
        })
        .finally(() => context.commit('SET_LOADING_USERS', false));
    },
    ADD_USER({ commit, getters }: { commit: Commit, getters: any}, userData: any) {
      commit('SET_LOADING_USERS', true);

      return Vue.prototype.$hobApi
        .post('/users', userData)
        .then((response: AxiosResponse) => {
          const user = responseToUser({
            ...response.data.data,
            companyName: userData.companyName,
            groups: getters.getCurrentUser
              .groups.filter((g: any) => userData.groups.includes(g.value)),
          });
          commit('ADD_USER', user);
          return user;
        })
        .finally(() => commit('SET_LOADING_USERS', false));
    },
    ADD_GROUP_MEMBER(context: ActionContext<any, any>, {
      groupId,
      userId,
    }: { groupId: string, userId: string}) {
      context.commit('SET_LOADING_GROUP_MEMBER', `${groupId}_${userId}`);

      return Vue.prototype.$hobApi
        .post(`/groups/${groupId}/members/${userId}`)
        .then(() => {
          const group = context.getters.getCurrentUser.getGroupById(groupId);
          if (group) {
            context.commit('ADD_GROUP_MEMBER', { group, userId });
          }
        })
        .finally(() => context.commit('SET_LOADING_GROUP_MEMBER', false));
    },
    DELETE_GROUP_MEMBER(context: ActionContext<any, any>, {
      groupId,
      userId,
    }: { groupId: string, userId: string}) {
      context.commit('SET_LOADING_GROUP_MEMBER', `${groupId}_${userId}`);

      return Vue.prototype.$hobApi
        .delete(`/groups/${groupId}/members/${userId}`)
        .then(() => {
          context.commit('REMOVE_GROUP_MEMBER', { groupId, userId });
        })
        .finally(() => context.commit('SET_LOADING_GROUP_MEMBER', false));
    },
  },
  getters: {
    getUsers: (state: any) => state.users,
    getUser: (state: any) => (userId: string) => state.users.find((u: User) => u.id === userId),
    isLoadingUsers: (state: any) => state.loadingUsers,
    hasUsers: (state: any) => state.users.length > 0,
    isLoadingGroupMember: (state: any) => (groupId: string, userId: string) => state
      .loadingGroupMember === `${groupId}_${userId}`,
  },
};

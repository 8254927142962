export enum PatchOp {
  Replace = 'replace',
  Add = 'add',
  Remove = 'remove',
}

export default class PatchRecord {
  op: PatchOp;

  path: string;

  value: any;

  constructor(op: PatchOp, path: string, value: any) {
    this.op = op;
    this.path = path;
    this.value = value;
  }
}

export const GRAPH_USER_PROPERTIES = [
  'displayName',
  'givenName',
  'mail',
  'surname',
  'id',
  'companyName',
  'mobilePhone',
];

class User {
  public id: string;

  public mail: string;

  public mobilePhone: string;

  public displayName: string;

  public givenName: string;

  public surname: string;

  public companyName: string;

  public photo?: string;

  public groups: Array<string>;

  public roles: Array<string>;

  constructor(
    id: string,
    mail: string,
    mobilePhone: string,
    displayName: string,
    givenName: string,
    surname: string,
    companyName: string,
    groups: Array<string>,
    roles: Array<string>,
    // photo: string,
  ) {
    this.id = id;
    this.mail = mail;
    this.mobilePhone = mobilePhone;
    this.displayName = displayName;
    this.givenName = givenName;
    this.surname = surname;
    this.companyName = companyName;
    this.groups = groups;
    this.roles = roles;
    // this.photo = photo;
  }

  public getGroupById(groupId: string) {
    return this.groups.find((g: any) => g.value === groupId);
  }

  public addGroup(group: any) {
    this.groups.push(group);
  }

  public removeGroup(groupId: string) {
    this.groups = this.groups.filter((g: any) => g.value !== groupId);
  }
}

export default User;

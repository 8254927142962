/* tslint:disable */
/* eslint-disable */
/**
 * HOB - API and data objects
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface Alarm
 */
export interface Alarm {
}


export enum AlarmStatus {
  Cleared = 'cleared',
  Warning = 'warning',
  Error = 'error',
}

export enum AlarmTypes {
  LowHigh = 'alarmLowHigh',
  NoData = 'alarmNoData',
  Boolean = 'alarmBoolean',
}

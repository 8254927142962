export default class PersonalNotification {
  id: string;

  name: string;

  mailAddresses: Array<string>;

  groups: Array<string> = [];

  constructor(
    id: string,
    name: string,
    mailAddresses: Array<string>,
    groups: Array<string>,
  ) {
    this.id = id;
    this.name = name;
    this.mailAddresses = mailAddresses;
    this.groups = groups;
  }
}

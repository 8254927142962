import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import nl from 'vuetify/src/locale/nl';
import en from 'vuetify/src/locale/en';
import helpers from '@/helpers';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, nl },
    current: helpers.locale,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: colors.grey.lighten4,
      },
      dark: {
        background: colors.grey.darken4,
      },
    },
  },
});

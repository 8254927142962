import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { ActionContext } from 'vuex';
import vuetify from '@/plugins/vuetify';
import AssetProject from '@/models/asset-project';

export default {
  state: {
    assetProjects: [],
    loadingAssetProjects: false,
    loadedAssetProjects: false,
  },
  mutations: {
    SET_ASSET_PROJECT(state: any, assetProjects: Array<AssetProject>) {
      state.assetProjects = assetProjects;
    },
    SET_LOADING_ASSET_PROJECT(state: any, loading: boolean) {
      state.loadingAssetProjects = loading;
    },
    SET_LOADED_ASSET_PROJECT(state: any, loaded: boolean) {
      state.loadedAssetProjects = loaded;
    },
  },
  actions: {
    FETCH_ASSET_PROJECTS(context: ActionContext<any, any>, {
      force = false,
    }: { force?: boolean } = {}) {
      if (context.getters.hasAssetProjects && !force) {
        return context.getters.getAssetProjects;
      }

      context.commit('SET_LOADING_ASSET_PROJECT', true);
      return Vue.prototype.$hobApi
        .get('/asset-projects')
        .then((response: AxiosResponse) => {
          const assetProjects = response.data.data.map((o: any) => new AssetProject(
            o.id,
            o.name,
            o.sensorTypeCalculations,
            o.assetIds,
            o.groups || [],
          ));
          context.commit('SET_ASSET_PROJECT', assetProjects);
          context.commit('SET_LOADED_ASSET_PROJECT', true);
          return assetProjects;
        })
        .finally(() => {
          context.commit('SET_LOADING_ASSET_PROJECT', false);
        });
    },
    ADD_ASSET_PROJECT(context: ActionContext<any, any>, assetProject: AssetProject) {
      return Vue.prototype.$hobApi
        .post('/asset-projects', assetProject)
        .then((response: AxiosResponse) => {
          context.dispatch('FETCH_ASSET_PROJECTS', { force: true });
          return response.data.data;
        });
    },
    UPDATE_ASSET_PROJECT(context: ActionContext<any, any>, assetProject: AssetProject) {
      return Vue.prototype.$hobApi
        .put(`/asset-projects/${assetProject.id}`, assetProject)
        .then((response: AxiosResponse) => {
          context.dispatch('FETCH_ASSET_PROJECTS', { force: true });
          return response.data.data;
        });
    },
    DELETE_ASSET_PROJECT(context: ActionContext<any, any>, assetProject: AssetProject) {
      return Vue.prototype.$hobApi
        .delete(`/asset-projects/${assetProject.id}`)
        .then((response: AxiosResponse) => {
          context.dispatch('FETCH_ASSET_PROJECTS', { force: true });
          return response.data;
        });
    },
  },
  getters: {
    getAssetProjects: (state: any) => state.assetProjects,
    hasAssetProjects: (state: any) => state.assetProjects.length > 0,
    loadedAssetProjects: (state: any) => state.loadedAssetProjects,
    getAssetProject: (state: any) => (id: string) => state.assetProjects
      .find((a: AssetProject) => a.id === id),
    isLoadingAssetProjects: (state: any) => state.loadingAssetProjects,
  },
};

<template lang="pug">
  v-app(:style="{ background: $vuetify.theme.themes[theme].background }")
    v-system-bar(
      app
      dark
      v-if="showBar && (hasVPN || isDevelopment || $auth.isAuthenticated)"
      :color="determineVPNColor"
    )
      v-icon {{ determineVPNIcon }}
      span.mr-3 VPN {{ hasVPN ? $t('vpn.connected') : $t('vpn.disconnected') }} {{ getVPNIp }}
      v-icon(v-if="isLoadingVPN") mdi-refresh mdi-spin
      v-btn(v-if="isDevelopment" x-small @click="testNotification")
        v-icon mdi-bell
        | Test notificatie
      v-spacer
      v-icon(@click="showBar = false") mdi-close
    v-snackbar(
      v-if="currentNotification"
      v-model="notification"
      top
      right
      :color="currentNotification.type"
      transition="fade-transition"
      multi-line
      vertical
    )
      | {{ currentNotification.message }}
      template(v-slot:action="{ attrs }")
        v-btn(
          v-if="currentNotification.btnText"
          v-bind="attrs"
          text
          @click="$router.push(currentNotification.btnTo), notification = false"
        )
          v-icon(v-if="currentNotification.btnIcon" left) {{ currentNotification.btnIcon }}
          | {{ currentNotification.btnText }}
        v-btn(
          v-else
          text
          v-bind="attrs"
          @click="notification = false"
        ) {{ $t('close') }}
    v-navigation-drawer(
      v-if="!isFullscreenPage && $auth.isAuthenticated"
      v-model="drawer"
      app
      :permanent="$vuetify.breakpoint.lgAndUp"
      dark
      color="primary"
      width="300"
    )
      template(v-slot:prepend)
        v-list-item(dense)
          v-list-item-avatar(tile v-if="currentOrganization")
            v-img(:src="currentOrganization.logoSrc" contain)
          v-list-item-content
            v-list-item-title
              | Asset Monitoring &amp; Control
            v-list-item-subtitle
              span(v-if="currentOrganization")
                | {{ currentOrganization.name }}
              span(v-else)
                | HOB IT Services B.V.
        v-divider
      template(v-slot:append)
        v-list(
          nav
          dense
        )
          v-menu(
            v-model="statusBoardMenu"
            offset-x
            transition="scale-transition"
            origin="bottom left"
            :close-on-content-click="false"
          )
            template(v-slot:activator="{ on }")
              v-list-item(
                v-on="on"
              )
                v-list-item-icon
                  v-icon mdi-monitor-dashboard
                v-list-item-content
                  v-list-item-title {{ $t('pages.status-overview') }}
                v-list-item-action
                    v-icon(small) mdi-chevron-right
            v-card(width="400")
              v-list-item(
                @click="statusBoardOpen()"
              )
                v-list-item-content
                  v-list-item-title {{ $t('assets.all') }}
                v-list-item-action
                    v-icon mdi-open-in-new
              v-divider
              v-card-text
                v-select(
                  v-model="statusBoardOrgIds"
                  :items="getOrganizations"
                  multiple
                  :hint="$t('assets.show-from-companies')"
                  persistent-hint
                  item-text="name"
                  item-value="id"
                  small-chips
                  dense
                  solo-inverted
                  append-outer-icon="mdi-open-in-new"
                  app
                  @click:append-outer="statusBoardOpenFiltered()"
                )
                  template(v-slot:selection="{ item, index }")
                    v-chip(v-if="index === 0" small) {{ item.name }}
                    span.grey--text.text-caption(v-if="index === 1")
                      | (+{{ statusBoardOrgIds.length - 1 }} {{ $t('others') }})
        v-divider
        ProductByHOB(dense)
      DesktopNotifications()
      v-list(nav)
        v-list-item-group
          v-list-item(
            v-for="(r, i) in routes"
            :key="i"
            :to="r"
            exact
          )
            v-list-item-icon
              v-badge(
                :value="r.name === 'assets' && (assetInAlarm || assetInMalfunction)"
                color="red"
                dot
              )
                v-icon {{ r.meta.icon }}
            v-list-item-content
              v-list-item-title {{ r.meta.title | ucFirst }}
              v-list-item-subtitle {{ r.meta.subtitle | ucFirst  }}
        template(v-if="myExternalResources.length")
          v-subheader {{ $t('third-party.external') }}
          v-list-item(
            v-for="(resource, i) in myExternalResources"
            :key="i"
            link
            @click="$store.dispatch('DOWNLOAD_RDP', { \
              hostname: resource.host, \
              name: resource.name, \
              domain: resource.domain, \
            })"
            target="_blank"
          )
            v-list-item-icon
              v-icon {{ resource.icon }}
            v-list-item-content
              v-list-item-title {{ resource.name }}
              v-list-item-subtitle(v-if="resource.type === 'rdp'") RDS Server
            v-tooltip(
              top
            )
              template(v-slot:activator="{ attrs, on }")
                v-list-item-action(
                  v-if="!hasVPN"
                )
                  v-icon(
                    v-bind="attrs"
                    v-on="on"
                  ) mdi-shield-off
              | {{ $t('vpn.connection') }}: {{ $t('vpn.disconnected') }}
    v-app-bar(
      v-if="!isFullscreenPage && $auth.isAuthenticated"
      :color="appBarColor"
      app
      elevate-on-scroll
      :dark="$vuetify.breakpoint.mdAndDown"
    )
      v-app-bar-nav-icon(
        v-if="$vuetify.breakpoint.mdAndDown"
        @click.stop="drawer = !drawer"
      )
      v-toolbar-title(v-if="$route && $route.meta && $route.meta.title")
        | {{ $route.meta.title | ucFirst }}
      v-spacer
      v-autocomplete.hidden-sm-and-down(
        :loading="isLoadingAssets"
        v-model="searchedAsset"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :label="$t('assets.search')"
        filled
        dense
        clearable
        :items="getAssets"
        item-text="name"
        item-value="id"
        return-object
      )
      v-spacer
      v-menu(
        fixed
        top
        transition="slide-y-transition"
        origin="top"
        :close-on-content-click="false"
      )
        template(v-slot:activator="{ on, attrs }")
          v-card.ma-0(
            v-if="$vuetify.breakpoint.mdAndUp"
            width="300"
            v-bind="attrs"
            v-on="on"
            flat
            color="transparent"
          )
            UserAccountListItem(:actions="true")
          v-btn(
            v-else
            v-bind="attrs"
            v-on="on"
            icon
          )
            v-icon mdi-account
        v-card(
          width="300"
        )
          UserAccountListItem(:actions="false")
          v-divider
          v-card-text
            v-switch(
              v-model="$vuetify.theme.dark"
              :label="$t('dark-mode')"
            )
            v-select(
              v-model="locale"
              @input="updateLocale"
              :items="langs"
              label="Language"
            )
          v-divider
          v-card-actions
            v-btn(@click="logout()" depressed block)
              v-icon(left) mdi-logout
              | {{ $t('auth.logout') }}
    v-main
      router-view
    v-overlay(
      v-if="isLoadingApp"
      color="primary"
      z-index="10000"
    )
      p.text-center {{ loadingAppMessage }}
      v-progress-linear(
        indeterminate
        color="white"
      )
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ROLE_ADMIN, ROLE_EDITOR } from '@/plugins/msal';
import UserAccountListItem from '@/components/UserAccountListItem.vue';
import DesktopNotifications from '@/components/notification/DesktopNotifications.vue';
import ProductByHOB from '@/components/ProductByHOB.vue';
import { NotificationType } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'App',

  components: {
    UserAccountListItem,
    ProductByHOB,
    DesktopNotifications,
  },

  data() {
    return {
      locale: null,
      showBar: process.env.NODE_ENV === 'development',
      drawer: false,
      searchedAsset: false,
      notification: false,
      currentNotification: null,
      statusBoardOrgIds: [],
      statusBoardMenu: false,
      langs: [
        { text: 'Nederlands', value: 'nl' },
        { text: 'English', value: 'en' },
      ],
      externalResources: [{
        type: 'rdp',
        groups: [
          '6d66c2ba-8416-4bd2-8c63-6f922136b0b7',
        ],
        name: this.$t('third-party.management-server'),
        host: '145.131.209.144',
        domain: '.',
        icon: 'mdi-remote-desktop',
      }],
      darkModeStorageKey: 'hob-monitoring:dark-mode',
    };
  },
  async mounted() {
    if (this.$auth.isAuthenticated) {
      this.fetch();
    }

    this.$store.dispatch('CHECK_HAS_VPN');
    setInterval(() => {
      this.$store.dispatch('CHECK_HAS_VPN');
    }, 30 * 1000);
    this.locale = this.$i18n.locale;
    this.$vuetify.theme.dark = localStorage.getItem(this.darkModeStorageKey) === 'true';
  },
  computed: {
    ...mapGetters([
      'getAssets',
      'isLoadingAssets',
      'isLoadingApp',
      'loadingAppMessage',
      'getNotifications',
      'isLoadingVPN',
      'hasVPN',
      'getVPNIp',
      'currentOrganization',
      'getOrganizations',
      'getUserAccount',
      'hasRoleEditor',
      'hasRoleAdmin',
      'isDevelopment',
      'getCurrentUser',
    ]),
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    myExternalResources() {
      const resources = this.externalResources
        .filter((r) => r.groups
          .filter((g) => this.getCurrentUser?.groups
            .map((_g) => _g.value)
            .includes(g))
          .length > 0);
      return resources;
    },
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },
    routes() {
      return this.$router.getRoutes()
        .filter((r) => !r.meta.hiddenMenu)
        .filter((r) => !r.meta.role
          || (r.meta.role === ROLE_EDITOR && this.hasRoleEditor)
          || (r.meta.role === ROLE_ADMIN && this.hasRoleAdmin));
    },
    assetInAlarm() {
      return this.getAssets.some((a) => a.hasAnyActiveAlarms);
    },
    assetInMalfunction() {
      return this.getAssets.some((a) => a.malfunction);
    },
    appBarColor() {
      return (this.$vuetify.breakpoint.mdAndDown)
        ? 'primary'
        : null;
    },
    isFullscreenPage() {
      return !!this.$route.meta.fullscreen;
    },
    determineVPNColor() {
      if (this.hasVPN) {
        return 'blue lighten-1';
      }
      return '';
    },
    determineVPNIcon() {
      if (this.hasVPN) {
        return 'mdi-shield-lock';
      }
      return '';
    },
  },
  methods: {
    logout() {
      this.$auth.signOut();
    },
    fetch() {
      this.$store.dispatch('LOAD_DATA');
    },
    toFullScreen() {
      const element = document.body;
      const requestMethod = element.requestFullScreen
        || element.webkitRequestFullScreen
        || element.mozRequestFullScreen
        || element.msRequestFullScreen;

      if (requestMethod) {
        requestMethod.call(element);
      }
    },
    testNotification() {
      this.$store.dispatch('ADD_NOTIFICATION', {
        type: NotificationType.Warning,
        message: 'Storing gemeld op \'TEST\'',
        btnIcon: 'mdi-alert-circle-outline',
        btnText: 'Bekijken',
        btnTo: { name: 'asset', params: { id: this.getAssets[0].id } },
      });
    },
    updateLocale() {
      this.$i18n.locale = this.locale;
      this.$store.dispatch('SET_LOADING_APP', this.$t('changing-language'));
      localStorage.setItem('locale', this.locale);
      this.$vuetify.lang.current = this.locale;
      window.location.reload();
    },
    statusBoardOpenFiltered() {
      this.statusBoardMenu = false;
      this.$router.push({
        name: 'status-overview',
        query: { organizationIds: this.statusBoardOrgIds },
      });
    },
    statusBoardOpen() {
      this.statusBoardMenu = false;
      this.$router.push({
        name: 'status-overview',
      });
    },
  },
  watch: {
    searchedAsset() {
      if (this.searchedAsset) {
        this.$router.push({ name: 'asset', params: { id: this.searchedAsset.id } });
      }
    },
    getNotifications(notifications) {
      if (notifications.length && !this.notification) {
        setTimeout(() => {
          [this.currentNotification] = notifications;
          this.notification = true;
        }, 500);
      }
    },
    notification(n) {
      if (!n && this.getNotifications) {
        this.$store.dispatch('SHIFT_NOTIFICATION');
      }
    },
    isDarkMode(d) {
      localStorage.setItem(this.darkModeStorageKey, d);
    },
    isAuthenticated(a) {
      if (a) this.fetch();
    },
  },
});
</script>

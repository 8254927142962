/* tslint:disable */
/* eslint-disable */
/**
 * HOB - API and data objects
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { SENSOR_TYPES, SENSOR_TYPE_ALARM } from "@/helpers/consts";
import axios from "axios";
import moment, { Moment } from 'moment';
import Vue from "vue";
import { Map } from ".";
import { Alarm } from "./alarm";
import { CONNECTOR_TYPE_XWEB, CONNECTOR_TYPE_AZUREIOT } from '@/models/data-connector';
import AlarmSensor from "./alarm-sensor";
import { AlarmType } from "./alarm-type";
import Component from "./component";
import DataConnector from "./data-connector";
import Malfunction from "./malfunction";
import { Meta } from "./meta";
import Organization from "./organization";
import { Sensor } from "./sensor";

/**
 *
 * @class Asset
 */
export default class Asset {
  /**
   * 
   * @type {string}
   * @memberof Asset
   */
  id: string;
  /**
   * 
   * @type {string}
   * @memberof Asset
   */
  name: string;
  /**
   * 
   * @type {string}
   * @memberof Asset
   */
  assetTypeId: string;
  /**
   * 
   * @type {Malfunction}
   * @memberof Asset
   */
  malfunction: Malfunction | null;
  /**
   * 
   * @type {Meta}
   * @memberof Asset
   */
  meta: Meta;
  /**
   * 
   * @type {Array<Sensor>}
   * @memberof Asset
   */
  sensors: Array<Sensor>;
  /**
   * 
   * @type {Array<string>}
   * @memberof Asset
   */
  groups: Array<string>;
  /**
   * 
   * @type {string}
   * @memberof Asset
   */
  organizationId: string | null;
  /**
   * 
   * @type {string}
   * @memberof Asset
   */
  ip: string | null;
  /**
   * 
   * @type {string}
   * @memberof Asset
   */
  vpnIp: string | null;
  /**
   * 
   * @type {Array<Component>}
   * @memberof Asset
   */
  components: Array<Component>;
  /**
   * 
   * @type {Array<DataConnector>}
   * @memberof Asset
   */
  connectors: Array<DataConnector>;
  /**
   * 
   * @type {any}
   * @memberof Asset
   */
  location?: any;
  /**
   * 
   * @type {Array<AlarmSensor>}
   * @memberof Asset
   */
  alarmsSensors: Array<AlarmSensor>;

  constructor(
    id: string,
    name: string,
    assetTypeId: string,
    malfunction: Malfunction | null = null,
    meta: Meta,
    sensors: Array<Sensor> = [],
    groups: Array<string> = [],
    organizationId: string | null = null,
    ip: string | null = null,
    vpnIp: string | null = null,
    components: Array<Component> = [],
    connectors: Array<DataConnector> = [],
    alarmsSensors: Array<AlarmSensor> = [],
  ) {
    this.id = id;
    this.name = name;
    this.assetTypeId = assetTypeId;
    this.malfunction = malfunction;
    this.meta = meta;
    this.sensors = sensors;
    this.groups = groups;
    this.organizationId = organizationId;
    this.ip = ip;
    this.vpnIp = vpnIp;
    this.components = components;
    this.connectors = connectors;
    this.alarmsSensors = alarmsSensors;
  };

  public getSensorBySensorTypeKey(sensorTypeKey: string) {
    return this.sensors
      .find((s: Sensor) => SENSOR_TYPES[sensorTypeKey].UUID.includes(s.sensorType.id));
  }

  public getSensorBySensorTypeId(sensorTypeId: string) {
    return this.sensors.find((s: Sensor) => s.sensorType.id === sensorTypeId);
  }

  public updateSensor(sensor: Sensor) {
    if (!this) return;
    const sensorIndex = this.sensors.findIndex((s: Sensor) => s.id === sensor.id);
    if (sensorIndex === -1) return;
    Vue.prototype.$set(this.sensors, sensorIndex, sensor);
  }

  public updateAlarmSensor(alarmSensor: AlarmSensor) {
    if (!this) return;
    const alarmIndexIndex = this.alarmsSensors
      .findIndex((s: AlarmSensor) => s.id === alarmSensor.id);
    if (alarmIndexIndex === -1) {
      this.alarmsSensors.unshift(alarmSensor);
    } else {
      Vue.prototype.$set(this.alarmsSensors, alarmIndexIndex, alarmSensor);
    }
  }

  public getSensor(id: String) {
    return this.sensors.find((s: Sensor) => s.id === id);
  }

  public isAlarmWarningActive(alarm: AlarmType) {
    return this.activeAlarmWarnings.filter((a) => alarm.id === a.alarmId).length > 0;
  }
  
  public isAlarmErrorActive(alarm: AlarmType) {
    return this.activeAlarmErrors.filter((a) => alarm.id === a.alarmId).length > 0;
  }

  public getLatLng() {
    const lat = this.getSensorBySensorTypeKey('LAT')?.lastMeasurement.value;
    const lon = this.getSensorBySensorTypeKey('LON')?.lastMeasurement.value;
    if (!lat || !lon) return false;
    return [lat, lon];
  }

  public get lastContact() {
    const lastContactRaw = Math.max(
      ...this.sensors.map((s: Sensor) => {
        if (s?.lastMeasurement?.dateTime) {
          return new Date(s.lastMeasurement.dateTime).getTime();
        }
        return 0;
      }),
      0,
    );
    const lastContact = (lastContactRaw !== 0)
      ? moment.utc(lastContactRaw)
      : null;
    return lastContact;
  }

  public get isOnline() {
    return this.lastContact && this.lastContact.isAfter(moment.utc().subtract(1, 'hour'));
  }

  public get alarms() {
    return this.alarmsSensors;
  }

  public sensorHasAlarmWarning(sensorId: string) {
    return this.alarmsSensors
      .filter((a) => !a.cleared && a.sensorId === sensorId && !a.delayPassed).length;
  }

  public sensorHasAlarmError(sensorId: string) {
    return this.alarmsSensors
      .filter((a) => !a.cleared && a.sensorId === sensorId && a.delayPassed).length;
  }

  public get activeAlarms() {
    return this.alarmsSensors.filter((a) => !a.cleared);
  }

  public get activeAlarmWarnings() {
    return this.activeAlarms.filter((a) => !a.delayPassed);
  }

  public get activeAlarmErrors() {
    return this.activeAlarms.filter((a) => a.delayPassed);
  }

  public get hasActiveAlarms() {
    return this.activeAlarms.length > 0;
  }

  public get hasActiveAlarmErrors() {
    return this.numberOfAlarmErrors > 0;
  }

  public get hasActiveAlarmWarnings() {
    return this.numberOfAlarmWarnings > 0;
  }

  public get numberOfAlarmErrors() {
    return this.activeAlarmErrors?.length || 0 + this.activeAlarmsBySensors?.length || 0;
  }

  public get numberOfAlarmWarnings() {
    return this.activeAlarmWarnings?.length || 0;
  }

  public get numberOfAlarms() {
    return this.numberOfAlarmErrors + this.numberOfAlarmWarnings;
  }

  public get alarmsBySensors() {
    return this.sensors
      .filter((s: Sensor) => s.sensorType.dataType === SENSOR_TYPE_ALARM);
  }

  public get activeAlarmsBySensors() {
    return this.alarmsBySensors
      .filter((s: Sensor) => moment.utc(s.lastMeasurement.dateTime)
        .isAfter(moment.utc().subtract(1, 'day')));
  }

  public get hasActiveAlarmsBySensors() {
    return this.activeAlarmsBySensors.length > 0;
  }

  public get hasAnyActiveAlarms() {
   return this.hasActiveAlarms || this.hasActiveAlarmsBySensors; 
  }

  public get hasAlerts() {
    return this.hasAnyActiveAlarms || this.malfunction;
  }

  public get inAzure() {
    return this.connectors.some((c) => c.connectorType === CONNECTOR_TYPE_AZUREIOT);
  }

  public async getLocation() {
    if (!this.location) {
      const latLng = this.getLatLng();
      if (!latLng) return false;
      try {
        const response = await axios
          .get(`https://nominatim.openstreetmap.org/reverse?lat=${latLng[0]}&lon=${latLng[1]}&format=jsonv2`);
        this.location = response.data;
      } catch {
        return false;
      }
    }
    return this.location;
  }
}

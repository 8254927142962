import Vue from 'vue';
import { ActionContext, Commit } from 'vuex';
import { AxiosResponse } from 'axios';
import PersonalNotification from '@/models/personal-notification';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export class AppNotification {
  type: NotificationType;

  message: string;

  btnIcon: string;

  btnText: string | null;

  btnTo: any | null;

  constructor(
    type: NotificationType,
    message: string,
    btnIcon: string,
    btnText: string | null = null,
    btnTo: any = null,
  ) {
    this.type = type;
    this.message = message;
    this.btnIcon = btnIcon;
    this.btnText = btnText;
    this.btnTo = btnTo;
  }
}

export default {
  state: {
    notifications: [],
    notificationPermission: ('Notification' in window) ? Notification.permission : null,
    personalNotifications: [],
    loadingPersonalNotifications: false,
    loadedPersonalNotifications: false,
  },
  mutations: {
    ADD_NOTIFICATION(state: any, notification: AppNotification) {
      state.notifications.push(notification);
    },
    SHIFT_NOTIFICATION(state: any) {
      state.notifications.shift();
    },
    UPDATE_NOTIFICATION_PERMISSION(state: any) {
      state.notificationPermission = ('Notification' in window) ? Notification.permission : null;
    },
    SET_PERSONAL_NOTIFICATIONS(state: any, personalNotifications: Array<PersonalNotification>) {
      state.personalNotifications = personalNotifications;
    },
    SET_LOADING_PERSONAL_NOTIFICATIONS(state: any, loading: boolean) {
      state.loadingPersonalNotifications = loading;
    },
    SET_LOADED_PERSONAL_NOTIFICATIONS(state: any, loaded: boolean) {
      state.loadedPersonalNotifications = loaded;
    },
  },
  actions: {
    ADD_NOTIFICATION({ commit, getters }: {
      commit: Commit,
      getters: any,
    }, notification: AppNotification) {
      commit('ADD_NOTIFICATION', notification);
      if (!('Notification' in window)) return;
      if (document.visibilityState !== 'visible'
        || !document.hasFocus()) {
        const browserNotification = new Notification('Asset Monitoring and Control', {
          body: notification.message,
          image: getters.currentOrganization?.logoSrc,
          icon: getters.currentOrganization?.logoSrc,
        });
        browserNotification.addEventListener('click', () => {
          browserNotification.close();
          Vue.prototype.$routerObject.push(notification.btnTo);
        });
      }
    },
    SHIFT_NOTIFICATION({ commit }: { commit: Commit }) {
      commit('SHIFT_NOTIFICATION');
    },
    REQUEST_NOTIFICATION_PERMISSION({ commit }: { commit: Commit }) {
      Notification.requestPermission().then((result) => {
        commit('UPDATE_NOTIFICATION_PERMISSION');
      });
    },
    GET_PERSONAL_NOTIFICATIONS(context: ActionContext<any, any>, {
      force = false,
    }: { force?: boolean } = {}) {
      if (context.getters.hasPersonalNotifications && !force) {
        return context.getters.getPersonalNotifications;
      }
      context.commit('SET_LOADING_PERSONAL_NOTIFICATIONS', true);

      return Vue.prototype.$hobApi
        .get('/personal-notifications')
        .then((response: AxiosResponse) => {
          const personalNotifications = response.data.data.map((o: any) => new PersonalNotification(
            o.id,
            o.name,
            o.mailAddresses,
            o.groups,
          ));
          context.commit('SET_PERSONAL_NOTIFICATIONS', personalNotifications);
          context.commit('SET_LOADED_PERSONAL_NOTIFICATIONS', true);
          return personalNotifications;
        })
        .finally(() => {
          context.commit('SET_LOADING_PERSONAL_NOTIFICATIONS', false);
        });
    },
    ADD_PERSONAL_NOTIFICATION(
      context: ActionContext<any, any>,
      personalNotification: PersonalNotification,
    ) {
      return Vue.prototype.$hobApi
        .post('/personal-notifications', personalNotification)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_PERSONAL_NOTIFICATIONS', { force: true });
          return response.data.data;
        });
    },
    UPDATE_PERSONAL_NOTIFICATION(
      context: ActionContext<any, any>,
      personalNotification: PersonalNotification,
    ) {
      return Vue.prototype.$hobApi
        .put(`/personal-notifications/${personalNotification.id}`, personalNotification)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_PERSONAL_NOTIFICATIONS', { force: true });
          return response.data.data;
        });
    },
    DELETE_PERSONAL_NOTIFICATION(
      context: ActionContext<any, any>,
      personalNotification: PersonalNotification,
    ) {
      return Vue.prototype.$hobApi
        .delete(`/personal-notifications/${personalNotification.id}`)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_PERSONAL_NOTIFICATIONS', { force: true });
          return response.data;
        });
    },
  },
  getters: {
    getNotifications: (state: any) => state.notifications,
    hasNotificationPermission: (state: any) => state.notificationPermission === 'granted',
    askForNotificationPermission: (state: any) => state.notificationPermission === 'default',
    getPersonalNotifications: (state: any) => state.personalNotifications,
    hasPersonalNotifications: (state: any) => state.personalNotifications.length > 0,
    loadedPersonalNotifications: (state: any) => state.loadedPersonalNotifications,
    getPersonalNotification: (state: any) => (id: string) => state.personalNotifications
      .find((a: PersonalNotification) => a.id === id),
    isLoadingPersonalNotifications: (state: any) => state.loadingPersonalNotifications,
  },
};

import { AxiosResponse } from 'axios';
import slugify from 'slugify';
import Vue from 'vue';
import { ActionContext, Commit, Dispatch } from 'vuex';
import FileDownload from 'js-file-download';
import moment from 'moment';
import i18n from '@/i18n';
import PatchRecord, { PatchOp } from '@/models/misc/patch-record';
import Asset from '@/models/asset';
import { Sensor } from '@/models/sensor';
import AssetType from '@/models/asset-type';
import { AppNotification, NotificationType } from './notifications';
import { AssetMessageType } from './signalr';
import { AlarmStatus } from '@/models/alarm';
import { AlarmType } from '@/models/alarm-type';
import Malfunction from '@/models/malfunction';
import AlarmSensor from '@/models/alarm-sensor';

export default {
  state: {
    assets: [] as Array<Asset>,
    loadingAssets: false,
    loadedAssets: false,
    loadingAssetDelete: false,
    loadingAssetConfig: false,
    assetTypes: [],
    loadingAssetTypes: false,
    loadedAssetTypes: false,
  },
  mutations: {
    SET_ASSETS(state: any, assets: Array<Asset>) {
      state.assets = assets;
    },
    SET_LOADING_ASSETS(state: any, loading: boolean) {
      state.loadingAssets = loading;
    },
    SET_LOADED_ASSETS(state: any, loaded: boolean) {
      state.loadedAssets = loaded;
    },
    SET_LOADING_ASSET_DELETE(state: any, loading: boolean) {
      state.loadingAssetDelete = loading;
    },
    SET_LOADING_ASSET_CONFIG(state: any, loading: boolean) {
      state.loadingAssetConfig = loading;
    },
    SET_SENSOR_IN_ASSET(state: any, sensor: Sensor) {
      const asset = state.assets.find((a: Asset) => a.id === sensor.assetId);
      asset.updateSensor(sensor);
    },
    SET_ALARM_SENSOR_IN_ASSET(state: any, alarmSensor: AlarmSensor) {
      const asset = state.assets.find((a: Asset) => a.id === alarmSensor.assetId);
      asset.updateAlarmSensor(alarmSensor);
    },
    SET_ASSET_TYPES(state: any, assetTypes: Array<AssetType>) {
      state.assetTypes = assetTypes;
    },
    SET_LOADING_ASSET_TYPES(state: any, loading: boolean) {
      state.loadingAssetTypes = loading;
    },
    SET_LOADED_ASSET_TYPES(state: any, loaded: boolean) {
      state.loadedAssetTypes = loaded;
    },
  },
  actions: {
    GET_ASSETS({ commit, getters, dispatch }: {
      commit: Commit,
      getters: any,
      dispatch: Dispatch,
    }) {
      if (getters.isLoadingAssets) {
        return;
      }

      commit('SET_LOADING_ASSETS', true);
      Vue.prototype.$hobApi
        .get('/assets')
        .then((response: AxiosResponse) => {
          const assets = response.data.data
            .map((a: any) => {
              const malfunction = a.malfunction ? new Malfunction(
                a.malfunction.text,
                a.malfunction.dateTime,
              ) : null;
              return new Asset(
                a.id,
                a.name,
                a.assetTypeId,
                malfunction,
                a.meta,
                a.sensors,
                a.groups || [],
                a.organizationId,
                a.ip,
                a.vpnIp,
                a.components || [],
                a.connectors || [],
                a.alarmsSensors,
              );
            });

          commit('SET_ASSETS', assets);
          commit('SET_LOADED_ASSETS', true);

          assets.forEach((a: Asset) => {
            dispatch('ADD_SOCKET_LISTENER', {
              id: a.id,
              func: (type: AssetMessageType, sensor: Sensor) => {
                if (type !== AssetMessageType.SensorUpdate) return;
                commit('SET_SENSOR_IN_ASSET', sensor);
                dispatch('ADD_REALTIME_MEASUREMENT', sensor);
              },
            });
            dispatch('ADD_SOCKET_LISTENER', {
              id: a.id,
              func: (type: AssetMessageType, newAsset: Asset) => {
                if (type !== AssetMessageType.Malfunction) return;
                const asset = getters.getAsset(newAsset.id);
                if (!asset) return;

                if (newAsset.malfunction) {
                  dispatch('ADD_NOTIFICATION', new AppNotification(
                    NotificationType.Warning,
                    i18n.t('assets.malfunction.reported-item', { item: newAsset.name }).toString(),
                    'mdi-alert-circle-outline',
                    i18n.t('view').toString(),
                    { name: 'asset', params: { id: newAsset.id } },
                  ));
                } else {
                  dispatch('ADD_NOTIFICATION', new AppNotification(
                    NotificationType.Success,
                    i18n.t('assets.malfunction.cleared-item', { item: newAsset.name }).toString(),
                    'mdi-alert-circle-check-outline',
                    i18n.t('view').toString(),
                    { name: 'asset', params: { id: newAsset.id } },
                  ));
                }
                Vue.prototype.$set(asset, 'malfunction', newAsset.malfunction);
              },
            });
            dispatch('ADD_SOCKET_LISTENER', {
              id: a.id,
              func: (
                type: AssetMessageType,
                status: AlarmStatus,
                sensor: Sensor,
                alarm: AlarmType | null,
                alarmSensor: AlarmSensor,
              ) => {
                if (![AssetMessageType.SensorAlarm, AssetMessageType.AssetAlarm].includes(type)) {
                  return;
                }
                const asset = getters.getAsset(sensor.assetId);
                if (!asset) return;

                let nType = NotificationType.Success;
                if (status === AlarmStatus.Warning) nType = NotificationType.Warning;
                else if (status === AlarmStatus.Error) nType = NotificationType.Error;

                const statusString = (status === AlarmStatus.Cleared) ? 'cleared' : 'reported';
                const alarmString = alarm ? `'${alarm.name}' ` : '';

                dispatch('ADD_NOTIFICATION', new AppNotification(
                  nType,
                  i18n.t(`assets.alarm.${statusString}`, {
                    alarm: alarmString,
                    sensor: sensor.sensorType.name,
                    asset: asset.name,
                  }).toString(),
                  'mdi-bell-ring-outline',
                  i18n.t('view').toString(),
                  { name: 'asset', params: { id: sensor.assetId } },
                ));
                commit('SET_SENSOR_IN_ASSET', sensor);
                commit('SET_ALARM_SENSOR_IN_ASSET', alarmSensor);
              },
            });
          });
        })
        .finally(() => {
          commit('SET_LOADING_ASSETS', false);
        });
    },
    ADD_ASSET({ dispatch }: { dispatch: Dispatch }, asset: Asset) {
      return Vue.prototype.$hobApi
        .post('/assets', asset)
        .then((response: AxiosResponse) => {
          dispatch('GET_ASSETS');
          return response.data;
        });
    },
    UPDATE_ASSET({ dispatch }: { dispatch: Dispatch }, asset: Asset) {
      return Vue.prototype.$hobApi
        .put(`/assets/${asset.id}`, asset)
        .then((response: AxiosResponse) => {
          dispatch('GET_ASSETS');
          return response.data;
        });
    },
    PATCH_ASSET(
      { dispatch }: { dispatch: Dispatch },
      { asset, patch }: { asset: Asset, patch: Array<PatchRecord> },
    ) {
      return Vue.prototype.$hobApi
        .patch(`/assets/${asset.id}`, patch)
        .then((response: AxiosResponse) => response.data.data);
    },
    DELETE_ASSET({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }, asset: Asset) {
      commit('SET_LOADING_ASSET_DELETE', true);

      return Vue.prototype.$hobApi
        .delete(`/assets/${asset.id}`)
        .then((response: AxiosResponse) => {
          dispatch('GET_ASSETS');
          return response.data;
        })
        .finally(() => {
          commit('SET_LOADING_ASSET_DELETE', false);
        });
    },
    DOWNLOAD_ASSET_CONFIG_RUT955({ commit }: { commit: Commit }, data: any) {
      commit('SET_LOADING_ASSET_CONFIG', true);

      return Vue.prototype.$hobApi
        .post(
          `/assets/${data.asset.id}/config/rut955`,
          {
            ...data,
          },
          {
            responseType: 'blob',
          },
        )
        .then((response: AxiosResponse) => {
          FileDownload(response.data, `${slugify(data.asset.name).toLowerCase()}-config.tar.gz`);
          return response;
        })
        .finally(() => {
          commit('SET_LOADING_ASSET_CONFIG', false);
        });
    },
    ASSET_REPORT_MALFUNCTION({ dispatch }: { dispatch: Dispatch }, {
      asset,
      malfunction,
    }: { asset: Asset, malfunction: Malfunction }) {
      return dispatch('PATCH_ASSET', {
        asset,
        patch: [
          new PatchRecord(PatchOp.Add, '/malfunction', malfunction),
        ],
      });
    },
    ASSET_CLEAR_MALFUNCTION({ dispatch }: { dispatch: Dispatch }, { asset }: { asset: Asset }) {
      return dispatch('PATCH_ASSET', {
        asset,
        patch: [
          new PatchRecord(PatchOp.Add, '/malfunction', null),
        ],
      });
    },
    GET_ASSET_TYPES(context: ActionContext<any, any>, { force = false }: { force?: boolean } = {}) {
      if (context.getters.hasAssetTypes && !force) return context.getters.getAssetTypes;

      context.commit('SET_LOADING_ASSET_TYPES', true);
      return Vue.prototype.$hobApi
        .get('/asset-types')
        .then((response: AxiosResponse) => {
          const assetTypes = response.data.data.map((o: any) => new AssetType(
            o.id,
            o.name,
            o.icon,
            o.kpis,
            o.components,
            o.groups,
          ));
          context.commit('SET_ASSET_TYPES', assetTypes);
          context.commit('SET_LOADED_ASSET_TYPES', true);
          return assetTypes;
        })
        .finally(() => {
          context.commit('SET_LOADING_ASSET_TYPES', false);
        });
    },
    ADD_ASSET_TYPE(context: ActionContext<any, any>, assetType: AssetType) {
      return Vue.prototype.$hobApi
        .post('/asset-types', assetType)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ASSET_TYPES', { force: true });
          return response.data.data;
        });
    },
    UPDATE_ASSET_TYPE(context: ActionContext<any, any>, assetType: AssetType) {
      return Vue.prototype.$hobApi
        .put(`/asset-types/${assetType.id}`, assetType)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ASSET_TYPES', { force: true });
          return response.data.data;
        });
    },
    DELETE_ASSET_TYPE(context: ActionContext<any, any>, assetType: AssetType) {
      return Vue.prototype.$hobApi
        .delete(`/asset-types/${assetType.id}`)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ASSET_TYPES', { force: true });
          return response.data.data;
        });
    },
  },
  getters: {
    getAssets: (state: any) => state.assets,
    hasAssets: (state: any) => state.assets.length > 0,
    loadedAssets: (state: any) => state.loadedAssets,
    getAsset: (state: any) => (id: string) => state.assets
      .find((a: Asset) => a.id === id),
    isLoadingAssets: (state: any) => state.loadingAssets,
    isLoadingAssetDelete: (state: any) => state.loadingAssetDelete,
    isLoadingAssetConfig: (state: any) => state.loadingAssetConfig,
    getAssetTypes: (state: any) => state.assetTypes,
    hasAssetTypes: (state: any) => state.assetTypes.length > 0,
    loadedAssetTypes: (state: any) => state.loadedAssetTypes,
    getAssetType: (state: any) => (id: string) => state.assetTypes
      .find((a: AssetType) => a.id === id),
    isLoadingAssetTypes: (state: any) => state.loadingAssetTypes,
  },
};

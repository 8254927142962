<template lang="pug">
  v-list-item(:dense="dense" link href="https://hob-it.nl" target="_blank")
    v-list-item-content
      v-list-item-subtitle {{ $t('product-by') }}
      v-list-item-title HOB IT Services B.V.
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ProductByHOB',
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

export const CONNECTOR_TYPE_XWEB = 'XWeb';
export const CONNECTOR_TYPE_AZUREIOT = 'AzureIoT';

export default class DataConnector {
  connectorType: string;

  options: any;

  constructor(
    connectorType: string,
    options: any,
  ) {
    this.connectorType = connectorType;
    this.options = options;
  }
}

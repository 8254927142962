export default class Malfunction {
  text: string;

  dateTime: string;

  constructor(
    text: string,
    dateTime: string,
  ) {
    this.text = text;
    this.dateTime = dateTime;
  }
}

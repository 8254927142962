<template lang="pug">
  v-list-item(v-if="getCurrentUser && !isUserLoading")
    v-list-item-avatar(color="primary")
      v-avatar(size="40")
        span.white--text {{ initials }}
    v-list-item-content
      v-list-item-title {{ getCurrentUser.givenName }} {{ getCurrentUser.surname }}
      v-list-item-subtitle {{ getCurrentUser.companyName }}
    v-list-item-action(v-if="actions")
      v-btn(small icon)
        v-icon(color="grey darken-1") mdi-dots-vertical
  v-skeleton-loader(v-else type="list-item-avatar-two-line" color="transparent")
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'UserAccountListItem',
  props: {
    actions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'isUserLoading',
    ]),
    initials() {
      return this.getCurrentUser.givenName[0].toUpperCase()
        + this.getCurrentUser.surname[0].toUpperCase();
    },
  },
});
</script>

import Vue from 'vue';
import moment, { Moment } from 'moment';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import VueApexCharts from 'vue-apexcharts';
import colors from 'vuetify/lib/util/colors';
import MsalPlugin, { msalPluginInstance, MsalPluginOptions } from './plugins/msal';
import consts from './helpers/consts';
import helpers from './helpers';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import { AppNotification, NotificationType } from '@/store/modules/notifications';

Vue.config.productionTip = false;

const options: MsalPluginOptions = {
  clientId: String(process.env.VUE_APP_MSAL_CLIENT_ID),
  // authority: String(process.env.VUE_APP_MSAL_LOGIN_AUTHORITY),
  // passwordAuthority: 'https://login.microsoftonline.com/8f076b1a-66a6-40cd-8212-479e08c815a7',
  // knownAuthority: 'https://login.microsoftonline.com/8f076b1a-66a6-40cd-8212-479e08c815a7',
};

Vue.use(new MsalPlugin(router), options);

Vue.mixin({
  data() {
    return {
      mandatoryRule: (v: any) => !!v || i18n.t('validation.mandatory'),
      notEmptyRule: (v: Array<any>) => v.length > 0 || i18n.tc('validation.select-min', 1, { n: 1 }),
    };
  },
  computed: {
    chartOptions() {
      return {
        colors: [
          vuetify.framework.theme.themes.light.primary,
          vuetify.framework.theme.themes.light.secondary,
          vuetify.framework.theme.themes.light.accent,
        ],
        chart: {
          fontFamily: 'Roboto, sans-serif',
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
              customIcons: [],
            },
            autoSelected: 'zoom',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          tickAmount: 4,
          decimalsInFloat: 1,
          labels: {
            minWidth: 40,
          },
          opposite: true,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          markers: {
            radius: 0,
            // height: 2,
            offsetY: 2,
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        stroke: {
          width: 2,
        },
        tooltip: {
          x: {
            format: 'dd MMM HH:mm:ss',
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
      };
    },
  },
  methods: {
    print(x) {
      console.log(x);
    },
    copyText(t) {
      navigator.clipboard.writeText(t);
    },
    getFuelColor(value) {
      if (!value || value < consts.FUEL_LOW) return 'error';
      if (value < consts.FUEL_MED) return 'warning';
      return 'success';
    },
    getBatteryIcon(value) {
      const roundedTen = Math.round(value * 10) * 10;
      let iconSuffix = '';
      if (roundedTen < 100) {
        iconSuffix = `-${roundedTen}`;
      } else if (roundedTen < 10) {
        iconSuffix = '-alert-variant-outline';
      } else {
        iconSuffix = '';
      }
      const icon = `mdi-battery${iconSuffix}`;
      return icon;
    },
    getTitle(vm) {
      let v = vm;
      if (!vm) v = this;
      const { pageTitle } = v;
      if (pageTitle) {
        return typeof pageTitle === 'function'
          ? pageTitle.call(v)
          : pageTitle;
      }
      return false;

      // const title = vm.$route?.matched
      //   .slice()
      //   .reverse()
      //   .find((r) => r.meta && r.meta.title);

      // if (title?.meta?.title) {
      //   return title.meta.title;
      // }
      // return false;
    },
    foundPageTitle() {
      return document.title;
    },
    createAssetConnectionString(asset) {
      return `HostName=${process.env.VUE_APP_HOB_IOT_HUB};DeviceId=${asset.id};SharedAccessKey=${asset.primaryKey}`;
    },
  },
});

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

moment.locale(helpers.locale);
Vue.prototype.$moment = moment;

Vue.filter('round', (v: any) => {
  if (typeof v !== 'number') return v;
  return v.toFixed(Math.min(2, ((vv) => {
    if (Math.floor(vv.valueOf()) === vv.valueOf()) return 0;
    return vv.toString().split('.')[1].length || 0;
  })(v)));
});
Vue.filter('toMoment', (t: string) => moment.utc(t));
Vue.filter('toMomentLocal', (t: string) => moment.utc(t).local());
Vue.filter('momentCalendar', (m: Moment) => m.calendar());
Vue.filter('momentFromNow', (m: Moment) => m.fromNow());
Vue.filter('momentFormat', (m: Moment, f: string) => m.format(f || 'dd MMM yyyy [om] HH:mm'));
Vue.filter('ucFirst', helpers.ucFirst);
Vue.filter('determineScheme', (url: string) => {
  const splitted = url.split('//');
  if (splitted.length === 2) return url;
  if (splitted.length === 1) return `${window.location.protocol}//${url}`;
  return url;
});

// Vue.use(Auth0Plugin, {
//   domain: process.env.VUE_APP_AUTH0_DOMAIN,
//   clientId: process.env.VUE_APP_AUTH0_CLIENTID,
//   onRedirectCallback: (appState) => {
//     router.push(
//       appState && appState.targetUrl
//         ? appState.targetUrl
//         : window.location.pathname,
//     );
//   },
// });

Vue.prototype.$hobApi = axios.create({
  baseURL: process.env.VUE_APP_HOB_API,
});

Vue.prototype.$hobApi.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let accessToken;
    const c = { ...config };
    if (!msalPluginInstance.isAuthenticated) {
      throw new Error('Not authenticated');
    }
    try {
      accessToken = await msalPluginInstance.acquireToken(
        [String(process.env.VUE_APP_API_APP_USER_SCOPE)],
      );
      c.headers.Authorization = `Bearer ${accessToken}`;
      // c.headers['X-MS-TOKEN-AAD-ACCESS-TOKEN'] = `Bearer ${accessToken}`;
    } catch (error: any) {
      console.log(error);
    }
    return c;
  },
  (error: any) => Promise.reject(error),
);

Vue.prototype.$hobApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const statusCode = error.response?.status ?? 500;
    if ([401, 403].includes(statusCode)) {
      store.dispatch('ADD_NOTIFICATION', new AppNotification(
        NotificationType.Warning,
        i18n.t('errors.no-permissions').toString(),
        'mdi-minus-circle',
      ));
    } else if (statusCode === 500) {
      store.dispatch('ADD_NOTIFICATION', new AppNotification(
        NotificationType.Error,
        i18n.t('errors.something-went-wrong-contact').toString(),
        'mdi-alert',
      ));
    }
    return Promise.reject(error);
  },
);
Vue.prototype.$routerObject = router;

// Vue.prototype.$hobApi.interceptors.request.use(
//   async (config) => {
//     let accessToken = '';
//     const instance = getInstance();
//     try {
//       accessToken = await instance.getTokenSilently();
//     } catch (error) {
//       console.log(error);
//     }
//     const c = { ...config };
//     c.headers.Authorization = `Bearer ${accessToken}`;
//     return c;
//   },
//   (error) => Promise.reject(error),
// );

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

import * as msal from '@azure/msal-browser';
import Vue, { PluginObject, VueConstructor } from 'vue';
import VueRouter from 'vue-router';

export const ROLE_ADMIN = 'admin';
export const ROLE_EDITOR = 'editor';
declare module 'vue/types/vue' {
  interface Vue {
    $auth: MsalPlugin;
  }
}

export interface MsalPluginOptions {
  clientId: string;
  // authority: string;
  // passwordAuthority: string;
  // knownAuthority: string;
}

// eslint-disable-next-line
export let msalPluginInstance: MsalPlugin;

export default class MsalPlugin implements PluginObject<MsalPluginOptions> {
  private pluginOptions: MsalPluginOptions = {
    clientId: '',
    // authority: '',
    // passwordAuthority: '',
    // knownAuthority: '',
  };

  private router: VueRouter;

  public isAuthenticated = false;

  private isSigningIn = false;

  private isSigningOut = false;

  public msalInstance?: msal.PublicClientApplication;

  constructor(router: VueRouter) {
    this.router = router;
  }

  public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
    if (!options) {
      throw new Error('MsalPluginOptions must be specified');
    }
    this.pluginOptions = options;
    this.initialize(options);
    msalPluginInstance = this;
    // eslint-disable-next-line
    vue.prototype.$auth = Vue.observable(msalPluginInstance);
  }

  private initialize(options: MsalPluginOptions) {
    const redirectUri = new URL(`${window.location.protocol}//${window.location.host}/login`);
    const msalConfig: msal.Configuration = {
      auth: {
        ...options,
        // clientId: options.clientId,
        // authority: options.loginAuthority,
        knownAuthorities: [
          `${process.env.VUE_APP_MSAL_LOGIN_AUTHORITY}`,
          `${process.env.VUE_APP_MSAL_LOGIN_AUTHORITY_MICROSOFT}`,
          'https://sts.windows.net/8f076b1a-66a6-40cd-8212-479e08c815a7/',
        ],
        redirectUri: redirectUri.toString(),
      },
      cache: {
        cacheLocation: 'localStorage',
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message);
                return;
              case msal.LogLevel.Info:
                console.info(message);
                return;
              case msal.LogLevel.Verbose:
                console.debug(message);
                return;
              case msal.LogLevel.Warning:
                console.warn(message);
                return;
              default:
                console.log(message);
            }
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Verbose,
        },
      },
    };
    this.msalInstance = new msal.PublicClientApplication(msalConfig);
    this.msalInstance.handleRedirectPromise().then((response) => {
      if (response === null) return;
      this.isAuthenticated = this.getIsAuthenticated();
      this.router.push({ name: 'home' });
    });
    this.isAuthenticated = this.getIsAuthenticated();
  }

  public async signIn(signInAutority: string | undefined = undefined) {
    if (this.isSigningIn) return;
    this.isSigningIn = true;

    let authority = signInAutority;
    if (signInAutority) {
      localStorage.setItem('authority', signInAutority);
    } else {
      authority = `${localStorage.getItem('authority')}`;
    }
    try {
      const loginRequest: msal.RedirectRequest = {
        authority,
        scopes: [
          'openid',
          'profile',
          'offline_access',
          `${process.env.VUE_APP_API_APP_USER_SCOPE}`,
          'https://hobmonitoring.onmicrosoft.com/f45663f7-8b34-4a1f-8ed6-3f53331d3f58/User.Read',
        ],
      };
      const redirectResponse = await this.msalInstance?.loginRedirect(loginRequest);
    } catch (err) {
      console.log('Failed login', err);
      this.signOut();
    }
    this.isSigningIn = false;
  }

  public signOut() {
    if (this.isSigningOut) return false;
    this.isSigningOut = true;

    return this.msalInstance?.logoutRedirect().then(() => {
      this.isAuthenticated = false;
      this.isSigningOut = false;
      this.router.push({ name: 'login' });
    });
  }

  public async acquireToken(scopes: Array<string>): Promise<any> {
    const authority = localStorage.getItem('authority') || process.env.VUE_APP_MSAL_LOGIN_AUTHORITY;
    const request = {
      authority,
      account: this.getAccount(),
      scopes,
    } as msal.SilentRequest;
    try {
      const response = await this.msalInstance?.acquireTokenSilent(request);
      return response?.accessToken;
    } catch (e) {
      this.signOut();
    }
    return false;
  }

  private getIsAuthenticated(): boolean {
    return !!this.getAccount();
  }

  public getAccount(): msal.AccountInfo | null {
    const accounts = this.msalInstance?.getAllAccounts();
    if (accounts && accounts.length > 0) {
      return accounts[0];
    }
    return null;
  }
}

import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import FileDownload from 'js-file-download';
import { AxiosResponse } from 'axios';
import slugify from 'slugify';
import auth from '@/store/modules/auth';
import alarms from '@/store/modules/alarms';
import assets from '@/store/modules/assets';
import notifications from '@/store/modules/notifications';
import signalr from '@/store/modules/signalr';
import vpn from '@/store/modules/vpn';
import organizations from '@/store/modules/organizations';
import sensors from '@/store/modules/sensors';
import users from '@/store/modules/users';
import assetProject from '@/store/modules/asset-project';
import i18n from '@/i18n';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loadingApp: true,
    loadingAppMessage: i18n.t('app.loading'),
    environment: process.env.NODE_ENV,
  },
  mutations: {
    SET_LOADING_APP(state, message: string) {
      state.loadingApp = true;
      state.loadingAppMessage = message;
    },
    STOP_LOADING_APP(state) {
      state.loadingApp = false;
      state.loadingAppMessage = '';
    },
  },
  actions: {
    SET_LOADING_APP({ commit }, message: string) {
      commit('SET_LOADING_APP', message);
    },
    STOP_LOADING_APP({ commit }) {
      commit('STOP_LOADING_APP');
    },
    async LOAD_DATA({ dispatch }) {
      await dispatch('INIT_SIGNAL_R');
      dispatch('GET_ASSETS');
      dispatch('GET_ORGANIZATIONS');
      dispatch('GET_ASSET_TYPES');
      dispatch('RETRIEVE_USER');
    },
    DOWNLOAD_RDP(context: ActionContext<any, any>, { hostname, name, domain }: {
      hostname: string,
      domain: string,
      name: string,
    }) {
      const username = context.getters.getCurrentUser.givenName;
      const params = new URLSearchParams({
        hostname,
        username,
        domain,
      });
      return Vue.prototype.$hobApi
        .get(`/rdp?${params}`, {
          responseType: 'blob',
        })
        .then((response: AxiosResponse) => {
          FileDownload(
            response.data,
            `${slugify(name).toLocaleLowerCase()}-${slugify(username).toLocaleLowerCase()}.rdp`,
          );
          return response;
        });
    },
  },
  getters: {
    isLoadingApp: (state) => state.loadingApp,
    loadingAppMessage: (state) => state.loadingAppMessage,
    isDevelopment: (state) => state.environment === 'development',
  },
  modules: {
    auth,
    alarms,
    assets,
    signalr,
    notifications,
    vpn,
    organizations,
    sensors,
    users,
    assetProject,
  },
});

export default store;

<template lang="pug">
  LeafletMap(:height="height" :map-options="mapOptions")
    l-marker(
      v-for="(asset, i) in filteredAssets"
      :key="`${asset.id}-asset-map`"
      :lat-lng="asset.getLatLng()"
    )
      l-icon(
        :icon-size="[32, 32]"
        :icon-anchor="[16, 16]"
        color="primary"
      )
        v-badge(
          :value="asset.hasAnyActiveAlarms"
          color="error"
          overlap
          icon="mdi-bell-ring-outline"
        )
          v-btn(
            fab
            x-small
          )
            v-icon(color="primary") mdi-map-marker
      l-popup(:options="{ minWidth: 300 }")
        AssetCard(:asset="asset")
</template>

<script>
import Vue from 'vue';
import {
  LMarker,
  LIcon,
  LPopup,
} from 'vue2-leaflet';

import LeafletMap from '@/components/LeafletMap.vue';
import AssetCard from '@/components/assets/AssetCard.vue';

export default Vue.extend({
  name: 'AssetsMap',

  props: {
    assets: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
      default: 7,
    },
    center: {
      type: Array,
      default: () => [52, 5],
    },
    height: String,
  },
  components: {
    LMarker,
    LIcon,
    LPopup,
    LeafletMap,
    AssetCard,
  },
  mounted() {
  },
  computed: {
    mapOptions() {
      return {
        zoom: this.zoom,
        center: this.center,
        zoomControl: false,
      };
    },
    filteredAssets() {
      return this.assets
        .filter((a) => a.getLatLng());
    },
  },
  methods: {
  },
});
</script>

import { Commit, Dispatch } from 'vuex';
import * as signalR from '@microsoft/signalr';
import { msalPluginInstance } from '@/plugins/msal';

export enum AssetMessageType {
  SensorAlarm = 'sensor-alarm',
  AssetAlarm = 'asset-alarm',
  SensorUpdate = 'sensor-update',
  Malfunction = 'malfunction',
}

export default {
  state: {
    connection: null,
    listeners: [],
  },
  mutations: {
    SET_CONNECTION(state: any, connection: any) {
      state.connection = connection;
    },
    ADD_SOCKET_LISTENER(state: any, id: string) {
      state.listeners.push(id);
    },
  },
  actions: {
    async INIT_SIGNAL_R({ commit }: {
      commit: Commit,
      getters: any,
      dispatch: Dispatch,
    }) {
      if (!msalPluginInstance.isAuthenticated) {
        return;
      }

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.VUE_APP_HOB_API}/signalr`, {
            accessTokenFactory: () => msalPluginInstance.acquireToken(
              [String(process.env.VUE_APP_API_APP_USER_SCOPE)],
            ) as Promise<string>,
          },
        )
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      connection.start().then(() => console.log('Connected SignalR'));

      commit('SET_CONNECTION', connection);
    },
    ADD_SOCKET_LISTENER({ getters }: {
      getters: any,
    }, { id, func }: { id: string, func: Function }) {
      if (!getters.getSignalRConnection) return;
      if (getters.hasListener(id)) return;

      getters.getSignalRConnection.on(id, func);
    },
  },
  getters: {
    getSignalRConnection: (state: any) => state.connection,
    hasListener: (state: any) => (id: string) => state.listeners.includes(id),
  },
};

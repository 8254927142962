<template lang="pug">
  v-card(
    small
    :to="{ name: 'asset', params: { id: asset.id } }"
  )
    v-card-title.subtitle-1 {{ asset.name }}
    v-card-subtitle
      v-icon(small) mdi-update
      span.ml-2 {{ asset.lastContact | momentFromNow }}
    v-card-text
      //- v-icon(small :color="`${asset.alerts.length ? 'red' : 'green'} darken-1`")
      //-   | {{ asset.alerts.length ? 'mdi-alert' : 'mdi-check-circle' }}
      //- span.ml-2 {{ asset.alerts.length ? asset.alerts.length : 'Geen' }} meldingen
      template(v-if="asset.getSensorBySensorTypeKey('FUEL')")
        v-icon(
          small
          :color="getFuelColor(asset.getSensorBySensorTypeKey('FUEL').lastMeasurement.value)"
        ) mdi-fuel
        span.ml-2 {{ asset.getSensorBySensorTypeKey('FUEL').lastMeasurement.value }}
        span.grey--text %
      br
      v-icon(
        small
        color="primary"
      ) mdi-map-marker
      span.ml-2
        v-icon(v-if="loadingAssetLocation" small) mdi-loading mdi-spin
        template(v-else-if="location") {{ address }}
        em(v-else) {{ $t('no-address-available') }}
    v-divider
    v-card-actions
      v-spacer
      v-btn(
        text
        small
      )
        | {{ $t('more-info') }}
        v-icon(right) mdi-chevron-right
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'AssetCard',

  props: {
    asset: {
      type: Object,
    },
  },

  data() {
    return {
      loadingAssetLocation: false,
      location: null,
    };
  },
  async mounted() {
    this.loadingAssetLocation = true;
    this.location = await this.asset.getLocation();
    this.loadingAssetLocation = false;
  },
  computed: {
    address() {
      if (!this.location) return this.$t('not-available');
      const a = this.location.address;
      const number = a.house_number ? ` ${a.house_number}` : '';
      let city = '';
      if (a.village) city = a.village;
      else if (a.town) city = a.town;
      else if (a.municipality) city = a.municipality;
      else if (a.city) city = a.city;
      else city = this.$t('unknown');
      return `${a.road ? a.road : ''}${number},
        ${a.postcode ? a.postcode : ''} ${city}`;
    },
  },
});
</script>

<template lang="pug">
  div(v-if="hasNotificationsInWindow")
    v-expand-transition
      v-list-item.success(
        v-if="hasNotificationPermission && showAlways"
        dark
      )
        v-list-item-avatar.success(color="lighten-1")
          v-icon mdi-check
        v-list-item-content
          v-list-item-title {{ $t('desktop-notifications.on') }}
      v-list-item.warning(
        v-else-if="!askForNotificationPermission && showAlways"
        three-line
        dark
      )
        v-list-item-avatar.warning(color="lighten-1")
          v-icon mdi-close
        v-list-item-content
          v-list-item-title {{ $t('desktop-notifications.denied') }}
          v-list-item-subtitle {{ $t('desktop-notifications.browser-settings') }}
      v-list-item.light-blue(
        v-else-if="askForNotificationPermission"
        three-line
        @click="$store.dispatch('REQUEST_NOTIFICATION_PERMISSION')"
        dark
        :title="$t('desktop-notifications.turn-on')"
      )
        v-list-item-avatar.light-blue(
          color="lighten-1"
        )
          v-icon mdi-bell-ring-outline
        v-list-item-content
          v-list-item-title {{ $t('desktop-notifications.turn-on') }}
          v-list-item-subtitle {{ $t('desktop-notifications.explain') }}
  div(v-else-if="showAlways")
    v-list-item.warning(
      dark
    )
      v-list-item-avatar.warning(color="lighten-1")
        v-icon mdi-close
      v-list-item-content
        v-list-item-title {{ $t('desktop-notifications.not-available') }}
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'DesktopNotifications',
  props: {
    showAlways: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'hasNotificationPermission',
      'askForNotificationPermission',
    ]),
    hasNotificationsInWindow() {
      return ('Notification' in window);
    },
  },
});
</script>

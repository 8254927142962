// export default {
//   state: {
//     token: localStorage.getItem('user-token') || '',
//     status: '',
//   },
//   mutations: {
//     AUTH_REQUEST: (state) => {
//       state.status = 'loading';
//     },
//     AUTH_SUCCESS: (state, token) => {
//       state.status = 'success';
//       state.token = token;
//     },
//     AUTH_ERROR: (state) => {
//       state.status = 'error';
//     },
//     AUTH_LOGOUT: (state) => {
//       state.status = '';
//       state.token = '';
//     },
//   },
//   actions: {
//     AUTH_REQUEST: ({ commit }) => new Promise((resolve) => {
//       commit('AUTH_REQUEST');
//       localStorage.setItem('user-token', 'token');
//       commit('AUTH_SUCCESS', 'token');
//       resolve();
//     }),
//     AUTH_LOGOUT: ({ commit }) => new Promise((resolve) => {
//       commit('AUTH_LOGOUT');
//       localStorage.removeItem('user-token');
//       resolve();
//     }),
//   },
//   getters: {
//     isAuthenticated: (state) => !!state.token,
//     authStatus: (state) => state.status,
//   },
// };

import { ActionContext, Commit } from 'vuex';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';
import User, { GRAPH_USER_PROPERTIES } from '@/models/auth/user';

import { msalPluginInstance, ROLE_ADMIN, ROLE_EDITOR } from '@/plugins/msal';

export default {
  state: {
    user: null,
    userLoading: false,
  },
  mutations: {
    SET_USER(state: any, user: User) {
      state.user = user;
    },
    SET_USER_LOADING(state: any, loading: boolean) {
      state.userLoading = loading;
    },
  },
  actions: {
    async RETRIEVE_USER({ commit }: { commit: Commit }) {
      commit('SET_USER_LOADING', true);

      Vue.prototype.$hobApi
        .get('/auth/me')
        .then((response: AxiosResponse) => {
          const userData = response.data.data;
          const groups = userData.groups?.map((v: any) => ({
            value: v.id,
            text: v.displayName || v.id,
          }));
          const user = new User(
            userData.id,
            userData.mail,
            userData.mobilePhone,
            userData.displayName,
            userData.givenName,
            userData.surname,
            userData.companyName,
            groups || [],
            userData.roles || [],
          );
          // console.log(user);
          commit('SET_USER', user);
        })
        .finally(() => commit('SET_USER_LOADING', false));
    },
    // async RETRIEVE_USER({ commit }: { commit: Commit }) {
    //   commit('SET_USER_LOADING', true);

    //   const accessToken = await msalPluginInstance.acquireToken([
    //     // 'https://graph.microsoft.com/User.Read',
    //     'https://hobmonitoring.onmicrosoft.com/f45663f7-8b34-4a1f-8ed6-3f53331d3f58/User.Read',
    //     // 'https://graph.microsoft.com/Group.Read.All',
    //   ]);
    //   if (!accessToken) {
    //     commit('SET_USER_LOADING', false);
    //     return;
    //   }
    //   const microsoftGraphApi = axios
    //     .create(
    //       {
    //         baseURL: 'https://graph.microsoft.com/v1.0',
    //         headers: {
    //           Authorization: `Bearer ${accessToken}`,
    //         },
    //       },
    //     );
    //   Promise.all([
    //     microsoftGraphApi.get(`/me?$select=${GRAPH_USER_PROPERTIES.join(',')}`),
    //     microsoftGraphApi.get(
    //       '/me/memberOf/microsoft.graph.group?$select=id,displayName',
    //       { headers: { ConsistencyLevel: 'eventual' } },
    //     ),
    //   ])
    //     .then(([responseUser, responseGroups]) => {
    //       console.log(responseUser, responseGroups);
    //       const groups = responseGroups?.data?.value?.map((v: any) => {
    //         let groupText = v.id;
    //         if (v.displayName) {
    //           groupText = v.displayName;
    //         } else if (AD_GROUPS[v.id]) {
    //           groupText = AD_GROUPS[v.id];
    //         }
    //         return {
    //           value: v.id,
    //           text: groupText,
    //         };
    //       });
    //       const userData = responseUser.data;
    //       const user = new User(
    //         userData.id,
    //         userData.mail,
    //         userData.mobilePhone,
    //         userData.displayName,
    //         userData.givenName,
    //         userData.surname,
    //         userData.companyName,
    //         groups || [],
    //       );
    //       commit('SET_USER', user);
    //     })
    //     .finally(() => commit('SET_USER_LOADING', false));
    // },
  },
  getters: {
    getCurrentUser: (state: any) => state.user,
    isUserLoading: (state: any) => state.userLoading,
    getUserAccount: (state: any) => msalPluginInstance.getAccount(),
    getTokenClaims: (state: any, getters: any) => getters?.getUserAccount?.idTokenClaims,
    getUserRoles: (state: any, getters: any) => getters?.getCurrentUser?.roles,
    hasRoleAdmin: (state: any, getters: any) => getters
      .getUserRoles?.includes(ROLE_ADMIN),
    hasRoleEditor: (state: any, getters: any) => getters
      .getUserRoles?.includes(ROLE_EDITOR) || getters.hasRoleAdmin,
  },
};

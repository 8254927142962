<template lang="pug">
  v-container(fluid)
    v-row(v-if="isLoadingAssets")
      v-col(cols="12" sm="6" md="4" lg="3" v-for="(kpi, i) in kpis" :key="`${i}-a`")
        v-skeleton-loader(elevation="2" type="list-item-avatar-two-line")
    v-row
      v-col(cols="12" sm="6" md="4" lg="3" v-for="(kpi, i) in filteredKpis" :key="`${i}-a`")
        v-card
          v-list-item
            v-list-item-avatar(color="grey lighten-4")
              v-icon(color="primary") {{ kpi.icon }}
            v-list-item-content
              v-list-item-title
                span(:class="{ 'headline': $vuetify.breakpoint.mdAndUp }") {{ kpi.calc }}
                span.h4.grey--text &nbsp; / {{ kpi.calcOf }}
              v-list-item-subtitle {{ kpi.name }}
    v-row(v-if="filteredAssetsWithLatLng.length > 0")
      v-col(cols="12")
        v-card(
          :loading="isLoadingAssets"
        )
          AssetsMap(height="500" :assets="filteredAssetsWithLatLng")
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import consts from '@/helpers/consts';
import AssetsMap from '@/components/assets/AssetsMap.vue';

export default Vue.extend({
  name: 'Home',

  components: {
    AssetsMap,
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      'getAssets',
      'isLoadingAssets',
    ]),
    filteredKpis() {
      return this.kpis.filter((k) => k.calcOf > 0);
    },
    filteredAssetsWithLatLng() {
      return this.getAssets.filter((a) => a.getLatLng());
    },
    kpis() {
      return [
        {
          name: this.$t('online-last-hour'),
          calc: this.getAssets.filter((a) => a.isOnline).length,
          calcOf: this.getAssets.length,
          icon: 'mdi-wifi-sync',
        },
        // {
        //   name: 'In storing',
        //   calc: 2, // this.assets.filter((a) => a.alerts.length > 0).length,
        //   calcOf: 2, // this.assets.length,
        //   icon: 'mdi-alert',
        // },this
        {
          name: this.$t('fuel-level-lower', { level: String(consts.FUEL_LOW) }),
          calc: this.getAssets
            .filter((a) => a.getSensorBySensorTypeKey('FUEL')?.lastMeasurement.value <= consts.FUEL_LOW).length,
          calcOf: this.getAssets
            .filter((a) => ![null, undefined].includes(a.getSensorBySensorTypeKey('FUEL')?.lastMeasurement?.value))
            .length,
          icon: 'mdi-fuel',
        },
        {
          name: this.$tc('alarms.alarm', 2),
          calc: this.getAssets
            .filter((a) => a.hasAnyActiveAlarms).length,
          calcOf: this.getAssets.length,
          icon: 'mdi-bell-ring-outline',
        },
        // {
        //   name: 'Onderweg',
        //   calc: 2, // this.assets.filter((a) => a.vehicle).length,
        //   calcOf: 2, // this.assets.filter((a) => a.vehicle).length,
        //   icon: 'mdi-truck-fast-outline',
        // },
      ];
    },
  },
});
</script>

export default class AssetType {
  id: string;

  name: string;

  icon: string;

  kpis: Array<string> = [];

  components: Array<any> = [];

  groups: Array<string> = [];

  constructor(
    id: string,
    name: string,
    icon: string,
    kpis: Array<string> = [],
    components: Array<any> = [],
    groups: Array<string> = [],
  ) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.kpis = kpis;
    this.components = components;
    this.groups = groups;
  }
}

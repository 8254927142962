import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { ActionContext } from 'vuex';
import vuetify from '@/plugins/vuetify';
import Organization from '@/models/organization';

export default {
  state: {
    organizations: [],
    loadingOrganizations: false,
    loadedOrganizations: false,
  },
  mutations: {
    SET_ORGANIZATIONS(state: any, organizations: Array<Organization>) {
      state.organizations = organizations;
    },
    SET_LOADING_ORGANIZATIONS(state: any, loading: boolean) {
      state.loadingOrganizations = loading;
    },
    SET_LOADED_ORGANIZATIONS(state: any, loaded: boolean) {
      state.loadedOrganizations = loaded;
    },
  },
  actions: {
    GET_ORGANIZATIONS(context: ActionContext<any, any>, {
      force = false,
    }: { force?: boolean } = {}) {
      if (context.getters.hasOrganizations && !force) {
        return context.getters.getOrganizations;
      }

      context.commit('SET_LOADING_ORGANIZATIONS', true);
      return Vue.prototype.$hobApi
        .get('/organizations')
        .then((response: AxiosResponse) => {
          const organizations = response.data.data.map((o: any) => new Organization(
            o.id,
            o.name,
            o.hostnames,
            o.logo,
            o.color,
            o.groups || [],
          ));
          context.commit('SET_ORGANIZATIONS', organizations);
          context.commit('SET_LOADED_ORGANIZATIONS', true);
          const current = context.getters.currentOrganization;
          if (current) {
            vuetify.framework.theme.themes.light.primary = current.color;
            vuetify.framework.theme.themes.dark.primary = current.color;
            const favicon = document.getElementById('favicon') as HTMLAnchorElement;
            if (favicon) {
              favicon.href = current.logoSrc;
            }
          }
          return organizations;
        })
        .finally(() => {
          context.commit('SET_LOADING_ORGANIZATIONS', false);
        });
    },
    ADD_ORGANIZATION(context: ActionContext<any, any>, organization: Organization) {
      return Vue.prototype.$hobApi
        .post('/organizations', organization)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ORGANIZATIONS', { force: true });
          return response.data.data;
        });
    },
    UPDATE_ORGANIZATION(context: ActionContext<any, any>, organization: Organization) {
      return Vue.prototype.$hobApi
        .put(`/organizations/${organization.id}`, organization)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ORGANIZATIONS', { force: true });
          return response.data.data;
        });
    },
    DELETE_ORGANIZATION(context: ActionContext<any, any>, organization: Organization) {
      return Vue.prototype.$hobApi
        .delete(`/organizations/${organization.id}`)
        .then((response: AxiosResponse) => {
          context.dispatch('GET_ORGANIZATIONS', { force: true });
          return response.data;
        });
    },
  },
  getters: {
    getOrganizations: (state: any) => state.organizations,
    hasOrganizations: (state: any) => state.organizations.length > 0,
    loadedOrganizations: (state: any) => state.loadedOrganizations,
    getOrganization: (state: any) => (id: string) => state.organizations
      .find((a: Organization) => a.id === id),
    isLoadingOrganizations: (state: any) => state.loadingOrganizations,
    currentOrganization: (state: any) => state.organizations
      .find((o: Organization) => o.isCurrent()),
  },
};

const locale = localStorage.getItem('locale') || window?.navigator?.language || 'en';

const ucFirst = (s: string) => {
  const [first, ...rest] = s.split('');
  return String(first).toLocaleUpperCase(locale) + (Array.isArray(rest) ? rest.join('') : '');
};

export default {
  ucFirst,
  locale,
};
